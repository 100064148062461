import { useParams, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useState, useEffect } from "react"
import { BeatLoader } from "react-spinners"
import parse from "html-react-parser"

import ErrorPage from "../../../../components/ErrorPage/ErrorPage"
import DashboardLayout from "../../../../components/Dashboard/DashboardLayout"
import chevronRightIcon from "../../../../assets/svgs/chevron-right.svg"
import profileIcon from "../../../../assets/icons/dashboard/profile.svg"
import discoveryIcon from "../../../../assets/icons/dashboard/discovery.svg"
import chartIcon from "../../../../assets/icons/dashboard/chart.svg"
import noPictureIcon from "../../../../assets/images/no-picture-taking.png"
import ImageSlider from "../../../../components/ImageSlider/ImageSlider"
import { FreelancerMenu } from "../../../../components/Dashboard/constants"
import { privateServicesProjectsPath } from "../../../../assets/constants"
import { getFreelancerServiceProjectDetailsAsync } from "../../../../slices/freelancer/serviceProject"
import { getEventDate } from "../../../../utils/date"

const metadata = {
    title: "My Services - Project Details",
}

const AboutCard = ({ data }) => {
    const projectDuration = getEventDate(data.projectStart, data.projectEnd, true)
    return (
        <div className="bg-white text-start p-4 sm:p-5 border border-[#F8F8F8] rounded-lg">
            <div className="text-versich-dark-blue font-bold pb-4 border-b border-[#EDF0F5]">About</div>
            <div className="mt-5 p-1 space-y-6">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg900:max-xl1080:grid-cols-3 xl:grid-cols-3 gap-x-3 gap-y-6">
                    <div className="flex gap-[26px]">
                        <div>
                            <div className="bg-brandeis-blue/[0.08] inline-flex items-center justify-center min-w-[43px] min-h-[43px] rounded-[10px]">
                                <img className="w-6 h-6" src={profileIcon} alt="" />
                            </div>
                        </div>
                        <div className="text-versich-dark-blue text-sm">
                            <p className="font-semibold mb-[3px]">Client</p>
                            <div>{data.clientName}</div>
                        </div>
                    </div>
                    <div className="flex gap-[26px]">
                        <div>
                            <div className="bg-brandeis-blue/[0.08] inline-flex items-center justify-center min-w-[43px] min-h-[43px] rounded-[10px]">
                                <img className="w-6 h-6" src={discoveryIcon} alt="" />
                            </div>
                        </div>
                        <div className="text-versich-dark-blue text-sm">
                            <p className="font-semibold mb-[3px]">Project Start - Project End</p>
                            <div>{projectDuration}</div>
                        </div>
                    </div>
                    <div className="flex gap-[26px]">
                        <div>
                            <div className="bg-brandeis-blue/[0.08] inline-flex items-center justify-center min-w-[43px] min-h-[43px] rounded-[10px]">
                                <img className="w-6 h-6" src={chartIcon} alt="" />
                            </div>
                        </div>
                        <div className="text-versich-dark-blue text-sm">
                            <p className="font-semibold mb-[3px]">Project Size</p>
                            <div>{data.projectSize.title}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ServiceProjectDetails = () => {
    const { id, pid } = useParams()
    const [project, setProject] = useState(undefined)

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getFreelancerServiceProjectDetailsAsync(pid))
            .unwrap()
            .then(data => {
                setProject(data.payload)
            })
            .catch(() => setProject(null))
    }, [])

    const navigate = useNavigate()
    const serviceProjectsPath = privateServicesProjectsPath.replace(":sid", id)
    const editProjectPath = serviceProjectsPath + `${pid}/edit/`
    return (
        project === null
            ? <ErrorPage status={404} />
            : <DashboardLayout metadata={metadata} navlinks={FreelancerMenu} activePage="My Services">
                {project ? <div className="flex flex-col gap-y-2.5 h-full">
                    <div className="space-y-1.5">
                        <div className="flex justify-start pt-1">
                            <button className="inline-flex items-center gap-0.5" onClick={() => navigate(serviceProjectsPath)}>
                                <img src={chevronRightIcon} alt="" />
                                <span className="text-versich-light-blue text-sm font-semibold">Back</span>
                            </button>
                        </div>
                        <div className="flex max-sm:flex-col sm:items-center gap-2.5">
                            <div className="text-versich-dark-blue text-start flex-1 space-y-[2px]">
                                <div className="text-lg lg:text-xl font-semibold">{project.projectName}</div>
                                <div className="text-sm">View project breakdown</div>
                            </div>
                            <div className="font-semibold flex flex-wrap py-1">
                                <button
                                    className="bg-versich-blue hover:bg-versich-blue-hover text-white text-sm min-h-12 py-2 lg:py-3 px-4 lg:px-6 border-2 border-versich-blue hover:border-versich-blue-hover rounded-[10px]"
                                    type="button"
                                    onClick={() => navigate(editProjectPath)}>
                                    <span>Edit Project</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="flex-grow">
                        <div className="bg-white text-start space-y-5 mb-5 py-6 p-4 sm:p-5 shadow-card4 rounded-lg">
                            <div className="text-versich-dark-blue space-y-[30px]">
                                <div className="space-y-[14px] pb-4 border-b border-[#D0D0D04D]">
                                    <div className="text-lg font-semibold">{project.projectName} | {project.service.title}</div>
                                </div>
                                <div className="text-sm font-normal space-y-3 pb-[30px] border-b border-[#D0D0D04D] no-twp render_richtext">
                                    {parse(project.description)}
                                </div>
                            </div>
                            <AboutCard data={project} />
                            <div className="grid grid-cols-1 gap-y-5 pb-10">
                                <div className="text-versich-dark-blue text-lg font-semibold">Images from project</div>
                                {project.images && project.images.length > 0
                                    ? project.images.length > 1
                                        ? <div className="max-w-full xl:max-w-[800px] mx-auto aspect-video rounded-[11px]">
                                            <ImageSlider images={project.images} />
                                        </div>
                                        : <div className="w-full h-full max-w-full xl:max-w-[800px] mx-auto rounded-[11px] overflow-hidden">
                                            <img className="w-full rounded-[11px]" src={project.images[0]} />
                                        </div>
                                    : <div className="flex flex-col items-center justify-center w-full h-full p-4 py-16 border border-brandeis-blue/[0.08] rounded-[11px]" title="No images found">
                                        <img className="w-32" src={noPictureIcon} />
                                        <span className="text-versich-label text-sm">No images found</span>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                    : <div className="w-full h-full flex justify-center items-center">
                        <BeatLoader loading={true} color="#1D88ED" size={10} />
                    </div>
                }
            </DashboardLayout>
    )
}
export default ServiceProjectDetails