import { useNavigate } from "react-router-dom"
import { formatDistanceToNow } from "date-fns"
import { MdCreate, MdOutlineUpdate, MdCalendarMonth } from "react-icons/md"
import { FaSackDollar } from "react-icons/fa6"
import { GrMoney } from "react-icons/gr"
import { GiMoneyStack } from "react-icons/gi"
import Cookies from "js-cookie"
import parse from "html-react-parser"

import DashboardConfirmBtn from "../../../../components/Buttons/DashboardConfirmBtn"
import { COOKIE_REQUEST_REFID, serviceRelatedfreelancerPath } from "../../../../assets/constants"

const RequestCard = ({ data }) => {
    const navigate = useNavigate()
    const viewExpert = () => {
        Cookies.set(COOKIE_REQUEST_REFID, data.refId, { expires: 1 })
        const relaventExpertsPath = serviceRelatedfreelancerPath.replace(":id", data.serviceId)
        navigate(relaventExpertsPath)
    }
    return (
        <div className="p-5 md:p-10 space-y-4">
            <div className="text-start space-y-5">
                <div className="flex max-md:flex-col mb-1.5 md:mb-8">
                    <div className="flex-1 p-3">
                        <p className="text-xl lg:text-2xl text-versich-dark-blue font-semibold pb-3">{data.title}</p>
                        <div className="text-xs md:text-sm flex flex-col smaller:flex-row gap-y-3 gap-x-4">
                            <div className="inline-flex items-center gap-1 md:gap-1.5"><MdCreate /> Posted {formatDistanceToNow(data.createdAt, { addSuffix: true })}</div>
                            <div className="inline-flex items-center gap-1 md:gap-1.5"><MdOutlineUpdate /> Updated {formatDistanceToNow(data.updatedAt, { addSuffix: true })}</div>
                        </div>
                    </div>
                    <div className="pt-3 pl-3">
                        <DashboardConfirmBtn onClick={viewExpert}>View Expert</DashboardConfirmBtn>
                    </div>
                </div>
                <div className="text-sm p-3 py-4 md:py-6 border-t no-twp render_richtext">
                    {parse(data.description)}
                </div>
                <div className="text-sm grid md:grid-cols-2 lg:grid-cols-3 gap-y-6 gap-x-4 justify-between p-3 py-4 md:py-6 border-t">
                    <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5"><FaSackDollar />Payment Type: <span className="text-versich-dark-blue font-medium">{data.paymentType.title}</span></div>
                    <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5"><GrMoney />Budget: <span className="text-versich-dark-blue font-medium">{
                        data.budget.uid === "other" ? data.otherBudget : data.budget.title
                    }</span></div>
                    <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5"><GiMoneyStack />Currency: <span className="text-versich-dark-blue font-medium">{data.currency.title}</span></div>
                    {data.duration &&
                        <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5"><MdCalendarMonth />Project Duration: <span className="text-versich-dark-blue font-medium">{
                            data.duration.uid === "other" ? data.otherDuration : data.duration.title
                        }</span></div>
                    }
                </div>
                {(data.requirements || data.projectType || data.appPlatform
                    || data.needs || data.serviceNeed || data.businessType || data.businessSize
                    || data.industry || data.monetizationStrategy || data.decision)
                    &&
                    <div className="text-sm grid lg:grid-cols-2 gap-y-6 gap-x-4 justify-between p-3 py-4 md:py-6 border-t">
                        {data.requirements &&
                            <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5">Requirements: <span className="text-versich-dark-blue font-medium">{data.requirements.title}</span></div>
                        }
                        {data.projectType &&
                            <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5">Project Type: <span className="text-versich-dark-blue font-medium">{
                                data.projectType.uid === "other" ? data.otherProjectType : data.projectType.title
                            }</span></div>
                        }
                        {data.appPlatform &&
                            <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5">App Platform: <span className="text-versich-dark-blue font-medium">{
                                data.appPlatform.uid === "other" ? data.otherAppPlatform : data.appPlatform.title
                            }</span></div>
                        }
                        {data.softwareType &&
                            <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5">Software Type: <span className="text-versich-dark-blue font-medium">{
                                data.softwareType.uid === "other" ? data.otherSoftwareType : data.softwareType.title
                            }</span></div>
                        }
                        {data.softwareNature &&
                            <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5">Software Nature: <span className="text-versich-dark-blue font-medium">{
                                data.softwareNature.uid === "other" ? data.otherSoftwareNature : data.softwareNature.title
                            }</span></div>
                        }
                        {data.os &&
                            <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5">Operating System: <span className="text-versich-dark-blue font-medium">{
                                data.os.uid === "other" ? data.otherOs : data.os.title
                            }</span></div>
                        }
                        {data.needs &&
                            <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5">Needs: <span className="text-versich-dark-blue font-medium">{
                                data.needs.uid === "other" ? data.otherNeed : data.needs.title
                            }</span></div>
                        }
                        {data.serviceNeed &&
                            <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5">Service Need: <span className="text-versich-dark-blue font-medium">{
                                data.serviceNeed.uid === "other" ? data.otherServiceNeed : data.serviceNeed.title
                            }</span></div>
                        }
                        {data.businessType &&
                            <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5">Business Type: <span className="text-versich-dark-blue font-medium">{
                                data.businessType.uid === "other" ? data.otherBusiness : data.businessType.title
                            }</span></div>
                        }
                        {data.businessSize &&
                            <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5">Business Size: <span className="text-versich-dark-blue font-medium">{data.businessSize.title} employees</span></div>
                        }
                        {data.industry &&
                            <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5">Industry: <span className="text-versich-dark-blue font-medium">{
                                data.industry.uid === "other" ? data.otherIndustry : data.industry.title
                            }</span></div>
                        }
                        {data.monetizationStrategy &&
                            <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5">Monetization Strategy: <span className="text-versich-dark-blue font-medium">{
                                data.monetizationStrategy.uid === "other" ? data.otherMonetizationStrategy : data.monetizationStrategy.title
                            }</span></div>
                        }
                        {data.serviceEngagement &&
                            <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5">Service Engagement: <span className="text-versich-dark-blue font-medium">{
                                data.serviceEngagement.uid === "other" ? data.otherServiceEngagement : data.serviceEngagement.title
                            }</span></div>
                        }
                        {data.decision &&
                            <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5">Hiring Decision: <span className="text-versich-dark-blue font-medium">{
                                data.decision.uid === "other" ? data.otherDecision : data.decision.title
                            }</span></div>
                        }
                        {data.service &&
                            <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5">Custom Service: <span className="text-versich-dark-blue font-medium">{data.service}</span></div>
                        }
                        {data.expert &&
                            <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5">Expert: <span className="text-versich-dark-blue font-medium">{data.expert.title}</span></div>
                        }
                    </div>
                }
                {/* Expert Services */}
                {data.services &&
                    <div className="text-sm p-3 py-4 md:py-6 border-t">
                        {data.services &&
                            <div className="space-y-4">
                                <div className="text-versich-dark-blue font-semibold text-lg">Expert Services: </div>
                                <div className="flex flex-wrap flex-col smallest:flex-row gap-2">
                                    {data.services.map(service => (
                                        <div className="bg-anti-flash-white text-versich-darktext-color text-xs md:text-sm py-1 px-2.5 border border-versich-border/40 rounded-2xl" key={service.uid}>{service.title}</div>
                                    ))}
                                </div>
                            </div>
                        }
                    </div>
                }
                {/* Tools */}
                {data.tools &&
                    <div className="text-sm p-3 py-4 md:py-6 border-t">
                        {data.tools &&
                            <div className="space-y-4">
                                <div className="text-versich-dark-blue font-semibold text-lg">Prefered Tools: </div>
                                <div className="flex flex-wrap flex-col smallest:flex-row gap-2">
                                    {data.tools.map(tool => (
                                        <div className="bg-anti-flash-white text-versich-darktext-color text-xs md:text-sm py-1 px-2.5 border border-versich-border/40 rounded-2xl" key={tool.uid}>{tool.title}</div>
                                    ))}
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    )
}
export default RequestCard