import { useSelector } from "react-redux"

import DashboardLayout from "../../../components/Dashboard/DashboardLayout"
import CompleteProfile from "../../../components/Dashboard/CompleteProfile"
import About from "../../../components/Dashboard/client/About"
import HelpCard from "../../../components/Dashboard/HelpCard"
import Requests from "../../../components/Requests/Requests"
import { ClientMenu } from "../../../components/Dashboard/constants"

const ClientDashboard = () => {
    const { user } = useSelector(state => state.auth)
    return (
        <DashboardLayout navlinks={ClientMenu} activePage="Dashboard">
            <div className="text-versich-dark-blue text-start space-y-[2px]">
                <div className="text-lg lg:text-xl font-semibold">Hello {user.about.firstName}!</div>
                <div className="text-sm">Dashboard Overview</div>
            </div>
            <CompleteProfile />
            <div className="grid md:grid-cols-2 gap-x-4 gap-y-3">
                <About />
                <HelpCard className="max-md:hidden" />
            </div>
            <Requests />
            <HelpCard className="md:hidden" />
        </DashboardLayout>
    )
}
export default ClientDashboard