import { useSelector, useDispatch } from "react-redux"
import { useState } from "react"
import { formatDistanceToNow } from "date-fns"

import ReviewStars from "./ReviewStars"
import CSRFTokenField from "../../../../Inputs/CSRFTokenField"
import toastify from "../../../../../utils/toastify"
import HideWithVersiGemsDialog, { hideWithVersiGemsDialog } from "./HideWithVersiGemsDialog"
import { toggleReviewAndReferenceVisibilityAsync } from "../../../../../slices/reviewAndReferences"
import { FREELANCER } from "../../../../../assets/constants"
import { openDialog } from "../../../../DialogBox"

const ReviewCard = ({ data, hasVisibilityToggle, privatePage, reloadReviews }) => {
    const { user } = useSelector(state => state.auth)
    const [csrfToken, setCsrfToken] = useState("")
    const [visibility, setVisibility] = useState(data.visibility)

    const dispatch = useDispatch()
    const handleSubmit = (e) => {
        e.preventDefault()
        const submitBtn = e.target.querySelector("button[type=submit]")
        submitBtn.disabled = true

        dispatch(toggleReviewAndReferenceVisibilityAsync({ id: data.id, accountType: user.accountType, token: csrfToken }))
            .unwrap()
            .then((data) => {
                setVisibility(data.payload.visibility)
            })
            .catch((error) => {
                const data = JSON.parse(error.message)
                if (data.shortInfo && data.shortInfo === "invalid-plan") {
                    toastify(data.message, data.status, submitBtn)
                }
            })
            .finally(() => {
                submitBtn.disabled = false
            })
    }

    // Review Hidden for firsttime check
    const isFreelancer = user?.accountType === FREELANCER
    const freelancerUserHasAlreadyHiddenOnetime = isFreelancer && data.restriction?.hide_gems && data.restriction.hide_gems > 0
    return (<>
        <div className="flex max-smaller:flex-col smaller:items-center justify-between gap-y-1 gap-x-3">
            <p className="font-bold mb-[3px]">{data.name}</p>
            <ReviewStars average={data.ratings} />
        </div>
        <div className="font-medium">{data.review}</div>
        <div className="text-xs">{formatDistanceToNow(data.updatedAt, { addSuffix: true })}</div>
        {(hasVisibilityToggle || isFreelancer) &&
            privatePage &&
            <div>
                <form className="flex gap-2.5 items-center justify-end" onSubmit={freelancerUserHasAlreadyHiddenOnetime ? null : handleSubmit}>
                    <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                    <span>Public:</span>
                    {freelancerUserHasAlreadyHiddenOnetime
                        ? <button className="relative" type="button" onClick={openDialog} data-parent-id={hideWithVersiGemsDialog}>
                            <ToggleIcon visibility={visibility} dataParentId={hideWithVersiGemsDialog} />
                        </button>
                        : <button className="relative" type="submit">
                            <ToggleIcon visibility={visibility} />
                        </button>
                    }
                </form>
                {freelancerUserHasAlreadyHiddenOnetime &&
                    <HideWithVersiGemsDialog
                        requiredVerisGems={data.restriction.hide_gems}
                        reviewId={data.id}
                        reloadReviews={reloadReviews}
                        setVisibility={setVisibility}
                    />
                }
            </div>
        }
    </>)
}

const ToggleIcon = ({ visibility, dataParentId }) => {
    return (<>
        <div className={`box block h-[15px] w-[26px] rounded-full ${visibility ? "bg-versich-blue" : "bg-versich-darktext-color"}`} data-parent-id={dataParentId} />
        <div className={`absolute left-0.5 top-0.5 flex w-[11px] h-[11px] items-center justify-center rounded-full bg-white transition ${visibility ? "translate-x-full" : ""}`} data-parent-id={dataParentId} />
    </>)
}
export default ReviewCard