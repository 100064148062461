import { useParams, useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { BiSolidLock } from "react-icons/bi"

import ErrorPage from "../../../../components/ErrorPage/ErrorPage"
import DashboardLayout from "../../../../components/Dashboard/DashboardLayout"
import Meta from "../../../../components/Meta"
import BackButton from "../../../../components/Buttons/BackButton"
import BusinessMessageCard from "./components/BusinessMessageCard"
import BusinessRequestCard from "./components/BusinessRequestCard"
import { BusinessMenu } from "../../../../components/Dashboard/constants"
import { getBusinessDirectMessageAsync } from "../../../../slices/message/directMessage"
import { fetchRequestAction } from "../../request/details/utils"
import { allMessagePath } from "../../../../assets/constants"

const metadata = {
    title: "Message Details | VersiMarket",
    description: "Message details page for messages",
}

const MessageDetails = () => {
    const { id } = useParams()
    const [message, setMessage] = useState(undefined)
    const [request, setRequest] = useState(undefined)

    // Premium Plan check
    const { user } = useSelector(state => state.auth)
    const hasPremiumPlan = user.plan && user.plan.name === "premium" && user.plan.status === "active"

    const navigate = useNavigate()
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getBusinessDirectMessageAsync(id))
            .unwrap()
            .then(data => {
                setMessage(data.payload)
                if (!hasPremiumPlan) {
                    return
                }
                const actionAsync = fetchRequestAction(data.payload.requestRefId)
                dispatch(actionAsync(data.payload.requestRefId))
                    .unwrap()
                    .then(data => {
                        setRequest(data.payload)
                    })
                    .catch((error) => {
                        setRequest(null)
                    })
            })
            .catch(() => setMessage(null))
    }, [])
    return (
        message === null || request === null
            ? <ErrorPage status={404} />
            : <DashboardLayout navlinks={BusinessMenu} activePage="Messages">
                <div className="text-start mb-5 lg:mb-12 space-y-3">
                    <Meta data={metadata} />
                    <div className="bg-white shadow-formLight rounded-xl">
                        <div className="border-b-[3px] border-versich-blue/5 p-3 md:p-6">
                            <BackButton onClick={() => navigate(allMessagePath)} />
                        </div>
                        {message &&
                            <BusinessMessageCard data={message} hasPremiumPlan={hasPremiumPlan} />
                        }
                    </div>
                    <div className="bg-white shadow-formLight rounded-xl">
                        {request &&
                            hasPremiumPlan
                            ? <BusinessRequestCard data={request} />
                            : <div className="p-5 md:p-10 space-y-4">
                                <div className="text-start space-y-5">
                                    <div className="flex max-md:flex-col mb-1.5 md:mb-4">
                                        <div className="flex-1 p-3">
                                            <p className="text-xl lg:text-2xl text-versich-dark-blue font-semibold pb-3">Request Details:</p>
                                        </div>
                                    </div>
                                    <div className="w-full h-64 rounded-md relative">
                                        <div className="bg-[#F0F2FF] w-full h-full blur-[10px] rounded-xl" />
                                        <div className="flex items-center justify-center w-full h-full absolute top-0">
                                            <BiSolidLock className="text-oxford-blue/80 w-6 h-6" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </DashboardLayout>
    )
}
export default MessageDetails