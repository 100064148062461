import { useSelector, useDispatch } from "react-redux"
import { useState } from "react"

import curvedEditIcon from "../../../../assets/svgs/curved-edit.svg"
import DialogBox, { closeDialog, getDialog, openDialog } from "../../../DialogBox"
import FormLoader from "../../../loader/FormLoader"
import Close from "../../../svgs/Close"
import AddCompetitor from "./competitors/AddCompetitor"
import CSRFTokenField from "../../../Inputs/CSRFTokenField"
import toastify from "../../../../utils/toastify"
import { TOASTTIMER, TOASTTYPE_ERROR } from "../../../../assets/constants"
import { businessCompetitorsSetAsync } from "../../../../slices/business/competitors"
import AddPlus from "../../../svgs/AddPlus"

const Competitors = () => {
    const { user } = useSelector((state) => state.auth)

    const [csrfToken, setCsrfToken] = useState("")
    const [competitors, setCompetitors] = useState(null)
    const [submitted, setSubmitted] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()
    const handleSubmit = async (e) => {
        e.preventDefault()
        const submitBtn = e.target.querySelector("button[type=submit]")
        submitBtn.disabled = true

        setSubmitted(true)
        const competitorKeys = Object.keys(competitors)
        const emptyDetails = competitorKeys.filter(k =>
            competitors[k].companyName === "" ||
            competitors[k].website === "" ||
            competitors[k].uniqueServices === "" ||
            competitors[k].whyBetter === ""
        )
        if (emptyDetails.length > 0) {
            setTimeout(() => {
                submitBtn.disabled = false
                setSubmitted(false)
            }, TOASTTIMER)
            return toastify("Fill required fields to continue.", TOASTTYPE_ERROR, submitBtn)
        }
        setLoading(true)
        const formData = {
            competitors: competitorKeys.map(k => {
                return {
                    company_name: competitors[k].companyName,
                    website: competitors[k].website,
                    unique_services: competitors[k].uniqueServices,
                    why_better: competitors[k].whyBetter
                }
            }),
            token: csrfToken
        }
        dispatch(businessCompetitorsSetAsync(formData))
            .unwrap()
            .then(() => {
                const dialog = getDialog(e.target)
                if (dialog) {
                    closeDialog(null, dialog)
                }
                return setLoading(false)
            })
            .catch(error => {
                const data = JSON.parse(error?.message)
                return setErrorMsg(data.message)
            })
            .finally(() => {
                setLoading(false)
                submitBtn.disabled = false
                setSubmitted(false)
            })
    }
    const hasCompetitors = user.business.competitors && user.business.competitors.length > 0
    return (
        <div className="bg-white text-start p-4 sm:p-5 shadow-card5 rounded-lg">
            <div className="font-medium flex max-sm401:flex-col sm401:justify-between gap-x-3 gap-y-1.5 py-3 border-b border-[#EFEEEE99]">
                <span className="text-versich-dark-blue text-xl">Competitors</span>
                <button className="inline-flex items-center gap-1 hover:opacity-80" onClick={openDialog} data-parent-id={competitorsEditDialog}>
                    {hasCompetitors
                        ? <>
                            <img className="w-[18px] h-[18px]" src={curvedEditIcon} alt="" data-parent-id={competitorsEditDialog} />
                            <span className="text-versich-light-blue text-xs" data-parent-id={competitorsEditDialog}>Edit</span>
                        </>
                        : <>
                            <AddPlus className="[&>*]:stroke-versich-light-blue w-[18px] h-[18px]" dataParentId={competitorsEditDialog} />
                            <span className="text-versich-light-blue text-xs" data-parent-id={competitorsEditDialog}>Add</span>
                        </>
                    }
                </button>
            </div>
            <div>
                {hasCompetitors
                    ? user.business.competitors.map((competitor, index) => (
                        <div className="text-versich-dark-blue my-5 pt-5 first-of-type:pt-0 border-t-2 first-of-type:border-t-0 border-[#EFEEEE99]/50 space-y-8" key={index}>
                            <div className="grid grid-cols-1 sm:max-md:grid-cols-2 lg900:max-xl1080:grid-cols-2 xl:grid-cols-2 gap-x-3 gap-y-6">
                                <div className="text-sm">
                                    <p className="font-medium opacity-80 mb-[7px]">Company Name</p>
                                    <div className="font-semibold">{competitor.companyName}</div>
                                </div>
                                <div className="text-sm" style={{ overflowWrap: "anywhere" }}>
                                    <p className="font-medium opacity-80 mb-[7px]">Competitors's Website</p>
                                    <div className="font-semibold">{competitor.website}</div>
                                </div>
                            </div>
                            <div className="grid grid-cols-1 sm:max-md:grid-cols-2 lg900:max-xl1080:grid-cols-2 xl:grid-cols-2 gap-x-3 gap-y-6">
                                <div className="text-sm">
                                    <p className="font-medium opacity-80 mb-[7px]">Service you render they don't</p>
                                    <div className="font-semibold space-y-1.5">{competitor.uniqueServices.split("\n").map((desc, index) => <p key={index}>{desc}</p>)}</div>
                                </div>
                                <div className="text-sm">
                                    <p className="font-medium opacity-80 mb-[7px]">Why you're better than them</p>
                                    <div className="font-semibold space-y-1.5">{competitor.whyBetter.split("\n").map((desc, index) => <p key={index}>{desc}</p>)}</div>
                                </div>
                            </div>
                        </div>
                    ))
                    : <div className="text-versich-dark-blue my-5 pt-5 first-of-type:pt-0 border-t-2 first-of-type:border-t-0 border-[#EFEEEE99]/50 space-y-8" />
                }
            </div>
            <DialogBox
                id={competitorsEditDialog}
                className="backdrop:bg-[#1717253D] backdrop:backdrop-blur-sm max-md:max-w-lg lg:w-3/4 2xl:!w-2/3 !max-w-4xl"
                toastStacked={false}
                hideClose={true}
            >
                <div className="p-2.5 md:p-4">
                    <div className="flex gap-1.5">
                        <div className="text-versich-dark-blue flex-1">
                            <div className="text-xl font-semibold">Competitors</div>
                            <div className="text-sm mt-0.5">Manage your business competitors</div>
                        </div>
                        <div id="dialog_closebtn" className="text-right">
                            <button type="button" className="m-1 p-1" onClick={closeDialog} data-parent-id={competitorsEditDialog}>
                                <Close className="w-4 h-4" />
                            </button>
                        </div>
                    </div>
                    <form className="space-y-[34px]" method="dialog" onSubmit={handleSubmit} data-parent-id={competitorsEditDialog}>
                        <AddCompetitor maximum={4} minimum={1} submitted={submitted} competitors={user?.business?.competitors} setCompetitors={setCompetitors} />
                        <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                        {errorMsg && <div className="text-sm text-red-500 text-left mt-8 mb-4">{errorMsg}</div>}
                        <div className="text-sm font-semibold w-full flex max-sm401:flex-col justify-end gap-3 md:gap-5">
                            <button className="bg-white hover:opacity-80 text-versich-light-blue border border-versich-light-blue py-2.5 md:py-3 px-8 md:px-10 rounded-lg" type="button" onClick={closeDialog} data-parent-id={competitorsEditDialog}>Cancel</button>
                            <button className="bg-versich-blue hover:bg-versich-blue-hover disabled:bg-versich-blue-hover text-white disabled:opacity-70 py-2.5 md:py-3 px-8 md:px-10 rounded-lg disabled:cursor-not-allowed" type="submit">
                                {hasCompetitors ? "Update" : "Add"}
                            </button>
                        </div>
                    </form>
                </div>
                {loading && <FormLoader />}
            </DialogBox>
        </div>
    )
}
const competitorsEditDialog = "competitors-edit-dialog"
export default Competitors