import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../../../api"
import apiErrorParser from "../../../utils/apiErrorParser"

export const allWebsiteRequirementAsync = createAsyncThunk("service/WDAD/allWebsiteRequirement", async (params) => {
    try {
        const { data } = await api.websiteRequirementsWDAD(params)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const allWebsiteNeedAsync = createAsyncThunk("service/WDAD/allWebsiteNeed", async (params) => {
    try {
        const { data } = await api.websiteNeedsWDAD(params)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const createRequestAsync = createAsyncThunk("account/WDAD/createRequest",
    async ({
        user, requirement, need, otherNeed, businessType, otherBusinessType, industryType, otherIndustryType,
        projectDuration, otherProjectDuration, paymentType, currency, budget, otherBudget, hiringDecision, otherHiringDecision,
        clientEmail, clientName, description, token
    }) => {
        try {
            const reqData = {
                websiteRequirementId: requirement,
                websiteNeedId: need,
                otherWebsiteNeed: otherNeed,
                businessTypeId: businessType,
                otherBusinessType,
                industryTypeId: industryType,
                otherIndustryType,
                projectDurationId: projectDuration,
                otherProjectDuration,
                paymentTypeId: paymentType,
                currencyId: currency,
                estimatedBudgetId: budget,
                otherEstimatedBudget: otherBudget,
                hiringDecisionId: hiringDecision,
                otherHiringDecision,
                clientEmail,
                clientName,
                description,
                csrfmiddlewaretoken: token
            }
            const { data } = user ? await api.createRequestWDAD(reqData) : await api.createRequestAsGuestWDAD(reqData)
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })

export const getRequestAsync = createAsyncThunk("account/WDAD/getRequest", async (refId) => {
    try {
        const { data } = await api.getRequestWDAD(refId)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const getRequestTitleAsync = createAsyncThunk("account/WDAD/getRequestTitle", async (refId) => {
    try {
        const { data } = await api.getRequestTitleWDAD(refId)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})