
const PlusWithCircle = ({ className, dataParentId }) => {
    return (
        <svg className={className} data-parent-id={dataParentId} width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" fill="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g data-parent-id={dataParentId} transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                <path data-parent-id={dataParentId} d="M2330 5110 c-494 -48 -950 -230 -1350 -538 -195 -150 -448 -432 -594
                    -662 -63 -99 -186 -351 -230 -471 -49 -134 -102 -340 -128 -499 -31 -195 -31
                    -565 0 -760 45 -276 116 -498 237 -745 132 -269 269 -460 489 -681 221 -220
                    412 -357 681 -489 247 -121 469 -192 745 -237 195 -31 565 -31 760 0 276 45
                    498 116 745 237 269 132 460 269 681 489 220 221 357 412 489 681 88 179 132
                    296 180 476 63 240 78 371 78 649 0 278 -15 409 -78 649 -48 180 -92 297 -180
                    476 -132 269 -269 460 -489 681 -221 220 -412 357 -681 489 -246 121 -474 193
                    -740 235 -147 23 -475 34 -615 20z m468 -401 c488 -53 934 -270 1288 -623 415
                    -415 634 -943 634 -1526 0 -583 -219 -1111 -634 -1526 -415 -415 -943 -634
                    -1526 -634 -583 0 -1111 219 -1526 634 -415 415 -634 943 -634 1526 0 583 219
                    1111 634 1526 349 349 800 569 1276 623 110 12 376 13 488 0z"/>
                <path data-parent-id={dataParentId} d="M2515 3761 c-67 -17 -127 -74 -144 -138 -7 -24 -11 -190 -11 -440 l0
                    -403 -390 0 c-251 0 -408 -4 -442 -11 -65 -14 -129 -73 -148 -136 -27 -89 18
                    -191 102 -232 43 -20 56 -21 461 -21 l416 0 3 -422 c3 -407 4 -425 24 -463 11
                    -22 40 -53 63 -70 37 -26 51 -30 111 -30 60 0 74 4 111 30 23 17 52 48 63 70
                    20 38 21 56 24 463 l3 422 416 0 c405 0 418 1 461 21 84 41 129 143 102 232
                    -19 63 -83 122 -148 136 -34 7 -191 11 -442 11 l-390 0 0 403 c0 250 -4 416
                    -11 440 -11 43 -59 101 -98 121 -35 18 -99 26 -136 17z"/>
            </g>
        </svg>
    )
}
export default PlusWithCircle