import { useSelector, useDispatch } from "react-redux"
import { useState, useEffect } from "react"
import { Navigate, useNavigate } from "react-router-dom"

import Meta from "../../../components/Meta"
import AuthImgBanner from "../../../components/AuthImgBanner"
import Logo from "../../../components/Logo"
import ProfileUploader from "../../../components/ProfileUploader"
import { CLIENT, dashboardPath } from "../../../assets/constants"

const metadata = {
    title: "Set your Profile Picture - VersiMarket",
    description: "Upload a profile picture for your account, to showcase an unique identity and build trust"
}

const ProfilePictureForm = () => {
    const { user } = useSelector((state) => state.auth)
    const [disableContinue, setDisableContinue] = useState(false)
    const [initialRender, setInitialRender] = useState()

    // Track initial render
    useEffect(() => {
        setInitialRender(!(user.profile?.image || user.social?.image))
    }, [])

    // Track user-data (profile) update
    useEffect(() => {
        setDisableContinue(!(user.profile?.image || user.social?.image))
    }, [user])

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleClick = (e) => {
        if (user.profile?.image || user.social?.image) {
            navigate(dashboardPath)
        }
        else e.target.disabled = true
    }
    return (
        initialRender === true ?
            <div>
                <Meta data={metadata} />
                <div className="grid grid-cols-1 lg:grid-cols-2 min-h-svh">
                    <AuthImgBanner />
                    <div className="w-full m-auto space-y-5 lg:space-y-7 p-5 smaller:p-10 max-w-[580px] rounded-lg">
                        <Logo href={dashboardPath} />
                        <div className="text-center space-y-2">
                            <h2 className="text-xl md:text-2xl leading-normal text-versich-darktext-color font-semibold">Upload Your Profile Picture</h2>
                            <p className="text-versich-label">Showcase your unique identity and build trust within our community with a distinct visual representation</p>
                        </div>
                        <div className="space-y-6 lg:space-y-8 pb-6 lg:pb-8">
                            <div className="space-y-2">
                                <ProfileUploader user={user} name="profilePicture" dispatch={dispatch} centered={true} />
                            </div>
                            <div className="text-sm font-semibold w-full flex flex-col items-center justify-center gap-5 py-6">
                                {user.accountType === CLIENT && !(user.profile?.image || user.social?.image) &&
                                    <button className="bg-white hover:opacity-80 text-versich-light-blue border border-versich-light-blue py-2 lg:py-3 px-6 lg:px-10 rounded-lg" type="button" onClick={() => navigate(dashboardPath)}>Skip for later</button>
                                }
                                <button className="bg-versich-blue hover:bg-versich-blue-hover text-white disabled:bg-versich-blue/20 disabled:text-versich-darktext-color/80 disabled:cursor-not-allowed sm401:w-2/5 py-2 lg:py-3 px-6 lg:px-10 rounded-lg" type="button" onClick={handleClick} disabled={disableContinue}>Continue</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            : initialRender === false
                ? <Navigate to={dashboardPath} replace />
                : null
    )
}
export default ProfilePictureForm