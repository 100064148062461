import { useSelector, useDispatch } from "react-redux"
import { FormProvider, useForm } from "react-hook-form"
import { useState } from "react"

import PlusWithCircle from "../../../svgs/PlusWithCircle"
import Close from "../../../svgs/Close"
import DialogBox, { openDialog, closeDialog, getDialog } from "../../../DialogBox"
import FormLoader from "../../../loader/FormLoader"
import CSRFTokenField from "../../../Inputs/CSRFTokenField"
import InputText from "../../../Inputs/InputTextField"
import TextAreaField from "../../../Inputs/TextAreaField"
import RatingSelectionField, { ratingOptions } from "../../../Inputs/RatingSelectionField"
import { businessAddReviewAndReferenceAsync } from "../../../../slices/business/reviewAndReferences"
import { BUSINESS, TOASTTYPE_ERROR } from "../../../../assets/constants"
import toastify from "../../../../utils/toastify"

const AddReview = ({ reloadReviews }) => {
    const { user } = useSelector(state => state.auth)
    const methods = useForm()

    const [csrfToken, setCsrfToken] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [email, setEmail] = useState("")
    const [review, setReview] = useState("")
    const [ratings, setRatings] = useState(0)
    const [ratingsError, setRatingsError] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    // Pre-fill Form
    const resetForm = () => {
        setFirstName("")
        setLastName("")
        setCompanyName("")
        setEmail("")
        setReview("")
        setRatings(0)
    }

    // Close dialog
    const customCloseDialog = (e) => {
        resetForm()
        closeDialog(e)
    }

    const dispatch = useDispatch()
    const customHandleSubmit = (e) => {
        e.preventDefault()
        setRatingsError(ratingOptions.includes(ratings) === false)
        methods.handleSubmit(onSubmit)(e)
    }
    const onSubmit = async (data, event) => {
        try {
            const isValid = await methods.trigger()
            if (!isValid) {
                toastify("Form validation failed", TOASTTYPE_ERROR)
                return methods.reset()
            }
            if (ratingsError) {
                return methods.reset()
            }
            const formData = {
                firstname: data.firstName,
                lastname: data.lastName,
                companyname: data.companyName,
                email: data.businessEmail,
                review: data.review,
                ratings: ratings,
                token: csrfToken,
            }
            setLoading(true)
            await dispatch(businessAddReviewAndReferenceAsync(formData))
                .unwrap()
                .then((data) => {
                    reloadReviews(true)
                    const dialog = getDialog(event.target)
                    if (dialog) {
                        closeDialog(null, dialog)
                    }
                    methods.reset()
                    setLoading(false)
                    return toastify(data.message, data.status, null)
                })
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    return setErrorMsg(data.message)
                })
                .finally(() => setLoading(false))
        } catch (error) {
            console.error("Form submission error:", error)
        }
    }
    return (
        user.accountType === BUSINESS && user.plan && user.plan.name === "premium" && user.plan.status === "active" &&
        <>
            <button
                className="text-versich-blue hover:text-versich-blue-hover text-sm font-bold flex items-center gap-1 pl-2.5 smaller:border-l border-[#EDF0F5] group"
                type="button"
                title="Add review"
                onClick={openDialog}
                data-parent-id={addReviewDialog}
            >
                <PlusWithCircle className="[&>*]:fill-versich-blue group-hover:[&>*]:fill-versich-blue-hover smaller:max-lg:w-[18px] smaller:max-lg:h-[18px] w-5 h-5" dataParentId={addReviewDialog} />
                <span className="max-smaller:hidden" data-parent-id={addReviewDialog}>Add review</span>
            </button>
            <DialogBox
                id={addReviewDialog}
                className="backdrop:bg-[#1717253D] backdrop:backdrop-blur-sm max-md:max-w-lg lg:w-3/4 2xl:!w-2/3 !max-w-4xl !overflow-visible"
                toastStacked={false}
                hideClose={true}
                customCloseEvent={customCloseDialog}
            >
                <div className="space-y-[34px] p-2.5 md:p-4">
                    <div className="flex gap-1.5">
                        <div className="text-versich-dark-blue flex-1">
                            <div className="text-xl font-semibold">Add Review</div>
                            <div className="text-sm mt-0.5">Add your own review as client</div>
                        </div>
                        <div id="dialog_closebtn" className="text-right">
                            <button type="button" className="m-1 p-1" onClick={customCloseDialog} data-parent-id={addReviewDialog}>
                                <Close className="w-4 h-4" />
                            </button>
                        </div>
                    </div>
                    <FormProvider {...methods}>
                        <form className="space-y-[34px]" method="dialog" onSubmit={customHandleSubmit} data-parent-id={addReviewDialog}>
                            <div className="space-y-3">
                                <div className="grid grid-cols-1 gap-x-3 gap-5 lg:grid-cols-2">
                                    <InputText name="firstName" inputType="text" placeholder="e.g. Steve" label="First Name" onChange={(e) => setFirstName(e.target.value)} defaultValue={firstName} rules={{ required: "First name is required" }} />
                                    <InputText name="lastName" inputType="text" placeholder="e.g. Rogers" label="Last Name" onChange={(e) => setLastName(e.target.value)} defaultValue={lastName} rules={{ required: "Last name is required" }} />
                                </div>
                                <InputText name="companyName" inputType="text" placeholder="e.g. Business Solution" label="Company Name" onChange={(e) => setCompanyName(e.target.value)} defaultValue={companyName} />
                                <InputText name="businessEmail" inputType="email" placeholder="e.g. ronald@a2zsolutions.com" label="Business Email Address" onChange={(e) => setEmail(e.target.value)} defaultValue={email} />
                                <TextAreaField
                                    name="review"
                                    label="Review"
                                    placeholder="e.g. Technology consulting with Excellence, Marketing business with digital accuracy"
                                    onChange={(e) => setReview(e.target.value)}
                                    defaultValue={review}
                                    rules={{ required: "Field is required" }}
                                />
                                <RatingSelectionField
                                    name="ratings"
                                    label="Ratings"
                                    ratings={ratings}
                                    setRatings={setRatings}
                                    error={ratingsError}
                                    setError={setRatingsError}
                                />
                            </div>
                            <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                            {errorMsg && <div className="text-sm text-red-500 text-left mt-8 mb-4">{errorMsg}</div>}
                            <div className="text-sm font-semibold w-full flex max-sm401:flex-col justify-end gap-3 md:gap-5">
                                <button className="bg-versich-blue hover:bg-versich-blue-hover disabled:bg-versich-blue-hover text-white disabled:opacity-70 py-2.5 md:py-3 px-8 md:px-10 rounded-lg disabled:cursor-not-allowed" type="submit">Save Review</button>
                            </div>
                        </form>
                    </FormProvider>
                </div>
                {loading && <FormLoader />}
            </DialogBox>
        </>
    )
}
const addReviewDialog = "add-review-dialog"
export default AddReview