import { useState } from "react"
import { openEditor } from "react-profile"
import "react-profile/themes/default.min.css"

import CSRFTokenField from "./Inputs/CSRFTokenField"
import { BUSINESS, TOASTTIMER } from "../assets/constants"
import { updateProfilePictureAsync } from "../slices/account"
import { getInitials } from "../utils"

const ProfileUploader = ({ children, user, name, label, dispatch, centered }) => {
    const [csrfToken, setCsrfToken] = useState("")
    const [errorMsg, setErrorMsg] = useState("")

    const fullName = user.accountType === BUSINESS ? user.business.details.data.companyName : user.about.firstName + " " + user.about.lastName
    const initials = getInitials(fullName)

    const handleFileSelect = async (e) => {
        if (e.target.files.length === 0) {
            return
        }
        const result = await openEditor({
            src: e.target.files[0],
            initCrop: {
                unit: "px",
                width: 300,
                height: 300,
                x: 2,
                y: 2
            },
        })
        if (result.done) {
            const imgFile = result.editedImage.getDataURL()
            dispatch(updateProfilePictureAsync({ file: imgFile, token: csrfToken }))
                .unwrap()
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    setErrorMsg(data.message)
                    return setTimeout(() => {
                        setErrorMsg("")
                    }, TOASTTIMER)
                })
        }
    }
    return (
        <>
            {label && <label htmlFor={name} className="text-charcoal text-sm flex">{label}</label>}
            <label className={"bg-[#F6F9FF] w-[135px] h-[135px] smallest:w-[150px] smallest:h-[150px] sm:w-[160px] sm:h-[160px] rounded-full cursor-pointer relative group "
                + (centered ? "inline-flex" : "flex")
            }>
                {user.profile?.image
                    ? <img className="w-full h-full object-cover rounded-full" src={user.profile.image} alt="" />
                    : user.social?.image
                        ? <img className="w-full h-full object-cover rounded-full" src={user.social.image} alt="" />
                        : <div className="text-versich-darktext-color text-3xl md:text-4xl font-semibold flex justify-center items-center w-full h-full rounded-full">
                            {initials}
                        </div>
                }
                <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                <input id={name} name={name} type="file" accept=".jpeg,.jpg,.png,.webp" hidden onChange={handleFileSelect} />
                {children}
            </label>
            {errorMsg &&
                <div className="text-sm text-red-500 text-left">
                    {errorMsg}
                </div>
            }
        </>
    )
}
export default ProfileUploader