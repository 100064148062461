import { useSelector, useDispatch } from "react-redux"
import { useForm, FormProvider } from "react-hook-form"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import chatIcon from "../../../../assets/svgs/about.svg"
import Meta from "../../../../components/Meta"
import BrandBar from "../../../../components/Navbar/BrandBar"
import FormLoader from "../../../../components/loader/FormLoader"
import CSRFTokenField from "../../../../components/Inputs/CSRFTokenField"
import CheckboxBadgeOptions from "../../../../components/Inputs/CheckboxBadgeOptions"
import TextAreaField from "../../../../components/Inputs/TextAreaField"
import DropdownField from "../../../../components/Inputs/DropdownField"
import toastify from "../../../../utils/toastify"
import BusinessSetupTracker from "./BusinessSetupTracker"
import { TOASTTYPE_ERROR, businessPaths } from "../../../../assets/constants"
import {
    businessAboutAllServiceAsync,
    businessAboutAllTotalEmployeeAsync,
    businessAboutAllMinimumProjectSizeAsync,
    businessAboutAllAverageHourlyRateAsync,
    businessAboutSetAsync
} from "../../../../slices/business/about"

const metadata = {
    title: "Setup - About Business",
    description: "Fill information about your business to list your business",
    url: `${process.env.REACT_APP_CLIENT_URL}/auth/su/business/about/`
}

const BusinessAboutForm = () => {
    const { user } = useSelector((state) => state.auth)
    const methods = useForm()

    const [csrfToken, setCsrfToken] = useState("")
    const [services, setServices] = useState([])
    const [tagline, setTagline] = useState("")
    const [description, setDescription] = useState("")
    const [totalEmployeeOptions, setTotalEmployeeOptions] = useState([])
    const [totalEmployees, setTotalEmployees] = useState("")
    const [minProjectSizeOptions, setMinProjectSizeOptions] = useState([])
    const [minProjectSize, setMinProjectSize] = useState("")
    const [averageHourlyRateOptions, setAverageHourlyRateOptions] = useState([])
    const [averageHourlyRate, setAverageHourlyRate] = useState("")
    const [serviceError, setServiceError] = useState(false)
    const [otherServiceValue, setOtherServiceValue] = useState("")
    const [otherServiceInputError, setOtherServiceInputError] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    // Load dropdown values
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(businessAboutAllTotalEmployeeAsync(`?orderby=id`))
            .unwrap()
            .then((data) => {
                setTotalEmployeeOptions(data.payload)
            })
        dispatch(businessAboutAllMinimumProjectSizeAsync(`?orderby=id`))
            .unwrap()
            .then((data) => {
                setMinProjectSizeOptions(data.payload)
            })
        dispatch(businessAboutAllAverageHourlyRateAsync(`?orderby=id`))
            .unwrap()
            .then((data) => {
                setAverageHourlyRateOptions(data.payload)
            })
    }, [])

    // Pre-fill form
    useEffect(() => {
        if (user?.business?.about) {
            setServices(user.business.about.services)
            if (user.business.about.otherService) {
                setOtherServiceValue(user.business.about.otherService)
            }
            setTagline(user.business.about.tagline)
            setDescription(user.business.about.description)
            setTotalEmployees(user.business.about.totalEmployees?.uid || "")
            setMinProjectSize(user.business.about.minimumProjectSize?.uid || "")
            setAverageHourlyRate(user.business.about.averageHourlyRate?.uid || "")
        }
    }, [user])

    // Handle OtherInput change
    const handleOtherInputChange = (e) => {
        setOtherServiceValue(e.target.value)
        setOtherServiceInputError(false)
    }

    const navigate = useNavigate()
    const customHandleSubmit = (e) => {
        e.preventDefault()
        if (services.length === 0) {
            setServiceError(true)
        }
        else setOtherServiceInputError(otherServiceValue === "")
        methods.handleSubmit(onSubmit)()
    }
    const onSubmit = async (data) => {
        try {
            const isValid = await methods.trigger()
            if (!isValid) {
                toastify("Form validation failed", TOASTTYPE_ERROR)
                return methods.reset()
            }
            const formData = {
                services,
                ...(otherServiceValue && { otherService: otherServiceValue }),
                tagline, description,
                totalEmployees, minProjectSize, averageHourlyRate,
                token: csrfToken,
            }
            setLoading(true)
            await dispatch(businessAboutSetAsync(formData))
                .unwrap()
                .then(() => {
                    navigate(businessPaths.location)
                    methods.reset()
                })
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    return setErrorMsg(data.message)
                })
                .finally(() => setLoading(false))
        } catch (error) {
            console.error("Form submission error:", error)
        }
    }
    return (
        <FormProvider {...methods}>
            <div className="bg-[#FBFBFF] min-h-svh">
                <Meta data={metadata} />
                <BrandBar />
                <div className="max_screen_w mb-12 py-8 md:py-10 px-4 md:px-8 lg:px-16">
                    <div className="space-y-3 pb-8 md:pb-10">
                        <h1 className="text-versich-dark-blue text-xl sm:text-2xl text-left font-medium">Business Listings</h1>
                        <p className="text-versich-dark-blue/80 text-xs sm:text-sm text-left font-medium">Complete details below to list your business</p>
                    </div>
                    <div className="flex max-md:flex-col-reverse gap-5">
                        <div className="bg-white w-full mx-auto px-4 smaller:px-8 xl:px-12 py-8 rounded-[11px] shadow-card2">
                            <div className="flex gap-3 pb-4 border-b border-[#EFEEEE99]">
                                <img src={chatIcon} alt="" />
                                <div className="font-medium text-left space-y-[7px]">
                                    <h1 className="text-versich-dark-blue text-lg sm:text-xl">About Business</h1>
                                    <p className="text-versich-dark-blue/80 text-xs sm:text-sm">Enter required details below</p>
                                </div>
                            </div>
                            <form onSubmit={customHandleSubmit}>
                                <div className="space-y-5 py-7">
                                    <div className="relative">
                                        <p className="text-charcoal text-sm text-left pb-2">What does your business do? <span className="text-red-500 ml-1">*</span></p>
                                        <CheckboxBadgeOptions
                                            actionAsync={businessAboutAllServiceAsync}
                                            values={services}
                                            setValues={setServices}
                                            error={serviceError}
                                            setError={setServiceError}
                                            otherValue={otherServiceValue}
                                            onOtherInputChange={handleOtherInputChange}
                                            otherInputError={otherServiceInputError}
                                        />
                                    </div>
                                    <TextAreaField
                                        name="tagline"
                                        label="Business Tagline"
                                        placeholder="e.g. Technology consulting with Excellence, Marketing business with digital accuracy"
                                        onChange={(e) => setTagline(e.target.value)}
                                        defaultValue={tagline}
                                        rules={{ required: "Tagline is required" }}
                                    />
                                    <TextAreaField
                                        name="description"
                                        label="Description"
                                        placeholder="Write here..."
                                        onChange={(e) => setDescription(e.target.value)}
                                        defaultValue={description}
                                        rules={{ required: "Description is required" }}
                                    />
                                    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-x-3 gap-5">
                                        <DropdownField
                                            name="totalEmployees"
                                            label="Total Employees"
                                            options={totalEmployeeOptions}
                                            value={totalEmployees}
                                            placeholder="Select"
                                            rules={{ required: "Total Employees is required" }}
                                            onChange={(e) => setTotalEmployees(e.target.value)}
                                        />
                                        <DropdownField
                                            name="minProjectSize"
                                            label="Minimum Project Size"
                                            options={minProjectSizeOptions}
                                            value={minProjectSize}
                                            placeholder="Select"
                                            onChange={(e) => setMinProjectSize(e.target.value)}
                                        />
                                        <div className="max-xl:lg:col-span-2">
                                            <DropdownField
                                                name="averageHourlyRate"
                                                label="Average Hourly Rate"
                                                options={averageHourlyRateOptions}
                                                value={averageHourlyRate}
                                                placeholder="Select"
                                                onChange={(e) => setAverageHourlyRate(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                                </div>
                                {errorMsg && <div className="text-sm text-red-500 text-left mt-4">{errorMsg}</div>}
                                <div className="text-sm font-semibold w-full flex justify-end gap-5 py-6">
                                    <button className="bg-white hover:opacity-80 text-versich-light-blue border border-versich-light-blue py-2 lg:py-3 px-6 lg:px-10 rounded-lg" type="button" onClick={() => navigate(businessPaths.personalcontact)}>Back</button>
                                    <button className="bg-versich-blue hover:bg-versich-blue-hover text-white py-2 lg:py-3 px-6 lg:px-10 rounded-lg" type="submit">Continue</button>
                                </div>
                            </form>
                        </div>
                        <div>
                            <BusinessSetupTracker completed={[
                                "Business Details", "Personal Contact", "About Business"
                            ]} />
                        </div>
                    </div>
                </div>
                {loading && <FormLoader />}
            </div>
        </FormProvider>
    )
}
export default BusinessAboutForm
