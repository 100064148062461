import { useSearchParams, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import { BeatLoader } from "react-spinners"

import paymentFailedIcon from "../../assets/icons/payment-failed.png"
import BrandBar from "../../components/Navbar/BrandBar"
import ErrorPage from "../../components/ErrorPage/ErrorPage"
import { verifyCheckoutSessionAsync } from "../../slices/payments"
import { dashboardPath, TOASTTIMER } from "../../assets/constants"

const SECONDS_TO_REDIRECT = 4

const PaymentCanceled = () => {
    const [searchParams, _] = useSearchParams()
    const session_id = searchParams.get("session_id")

    const [renderPage, setRenderPage] = useState(false)
    const [secsToRedirect, setSecsToRedirect] = useState(SECONDS_TO_REDIRECT)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    useEffect(() => {
        let interval, timeout
        dispatch(verifyCheckoutSessionAsync({ sessionId: session_id, status: "canceled" }))
            .unwrap()
            .then(() => {
                setRenderPage(true)
                interval = setInterval(() => {
                    if (secsToRedirect > 0) {
                        setSecsToRedirect(sec => sec - 1)
                    }
                    else clearInterval(interval)
                }, 1000)
                timeout = setTimeout(() => {
                    navigate(dashboardPath)
                }, TOASTTIMER)
            })
            .catch(() => setRenderPage(null))

        return () => {
            clearInterval(interval)
            clearTimeout(timeout)
        }
    }, [])
    return (renderPage !== null
        ? <>
            <BrandBar />
            <div className="mb-12 py-10 md:py-14 px-3">
                <div className="max_screen_w rounded-md overflow-hidden">
                    <div className="col-span-2 flex justify-center text-start px-4 md:py-6">
                        <div className="bg-white shadow-form flex-1 flex flex-col gap-3 py-6 rounded-md px-4 max-w-[720px]">
                            {renderPage
                                ? <div className="flex flex-col items-center gap-y-4 px-3 sm404:px-6 md:px-12 py-10 md:py-14">
                                    <h3 className="text-versich-light-blue text-xl md:text-2xl font-bold text-center">Checkout Unsuccessful or Canceled!</h3>
                                    <img className="w-20 h-20 md:w-24 md:h-24" src={paymentFailedIcon} alt="payment failed" />
                                    <p className="text-versich-label w-full text-start max-md:text-base md:text-lg">The most common and important reasons:</p>
                                    <div className="pl-5">
                                        <ul className="list-disc list-outside max-md:text-sm">
                                            <li><span className="text-versich-darktext-color font-semibold">Insufficient Funds:</span> Not enough money in the account.</li>
                                            <li><span className="text-versich-darktext-color font-semibold">Expired Card:</span> The card used has expired.</li>
                                            <li><span className="text-versich-darktext-color font-semibold">Incorrect Payment Details:</span> Errors in card number, expiration date, CVV, or billing address.</li>
                                            <li><span className="text-versich-darktext-color font-semibold">Network Issues:</span> Temporary issues with the payment gateway or internet connection.</li>
                                        </ul>
                                    </div>
                                    <p className="text-versich-label max-md:text-sm mt-10">Redirecting to your dashboard in {secsToRedirect}s.</p>
                                </div>
                                : <div className="w-full h-64 flex justify-center items-center">
                                    <BeatLoader loading={true} color="#1D88ED" size={10} />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
        : <ErrorPage status={404} />
    )
}
export default PaymentCanceled