import { Link } from "react-router-dom"

import facebookIcon from "../../../assets/social-icons/facebook_color.svg"
import xIcon from "../../../assets/social-icons/x_color.svg"
import linkedinIcon from "../../../assets/social-icons/linkedin_color.svg"
import pinterestIcon from "../../../assets/social-icons/pinterest_color.svg"
import tiktokIcon from "../../../assets/social-icons/tiktok_color.svg"
import instagramIcon from "../../../assets/social-icons/instagram_color.svg"

export const SocialLink = ({ href, title, icon, label }) => {
    const LinkOrDiv = ({ children, className }) => (
        href
            ? <Link to={href} target="_blank" className={className}>
                {children}
            </Link>
            : <div className={className}>
                {children}
            </div>
    )
    return (
        <LinkOrDiv className={"flex gap-[26px] " + (href ? "group" : "opacity-50 cursor-not-allowed")}>
            <div>
                <div className="bg-brandeis-blue/[0.08] inline-flex items-center justify-center min-w-[43px] min-h-[43px] rounded-[10px]" title={title}>
                    <img className="w-6 h-6" src={icon} alt="" />
                </div>
            </div>
            {label &&
                <div className="text-versich-dark-blue text-sm w-[70%] overflow-hidden inline-flex flex-col">
                    <p className="font-semibold mb-[3px]">{label}</p>
                    <div className="group-hover:text-versich-light-blue truncate">{href || "-"}</div>
                </div>
            }
        </LinkOrDiv>
    )
}

const SocialMedia = ({ data, hideEmpty }) => {
    return (
        <div className="bg-white text-start p-4 sm:p-5 shadow-card4 rounded-lg">
            <div className="text-versich-dark-blue font-bold pb-4 border-b border-[#EDF0F5]">Social Media</div>
            <div className="flex flex-wrap gap-x-3 gap-y-6 mt-5 p-1">
                {((hideEmpty && data.facebook) || !hideEmpty) &&
                    <SocialLink href={data.facebook} icon={facebookIcon} title="Facebook" />
                }
                {((hideEmpty && data.x) || !hideEmpty) &&
                    <SocialLink href={data.x} icon={xIcon} title="X" />
                }
                {((hideEmpty && data.linkedin) || !hideEmpty) &&
                    <SocialLink href={data.linkedin} icon={linkedinIcon} title="Linkedin" />
                }
                {((hideEmpty && data.pinterest) || !hideEmpty) &&
                    <SocialLink href={data.pinterest} icon={pinterestIcon} title="Pinterest" />
                }
                {((hideEmpty && data.tiktok) || !hideEmpty) &&
                    <SocialLink href={data.tiktok} icon={tiktokIcon} title="Tiktok" />
                }
                {((hideEmpty && data.instagram) || !hideEmpty) &&
                    <SocialLink href={data.instagram} icon={instagramIcon} title="Instagram" />
                }
            </div>
        </div>
    )
}
export default SocialMedia