
const AskQuestion = ({ className, dataParentId }) => {
    return (
        <svg className={className} data-parent-id={dataParentId} width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" fill="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g data-parent-id={dataParentId} transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                <path data-parent-id={dataParentId} d="M2090 5109 c-358 -18 -864 -69 -1031 -104 -220 -47 -421 -158 -605
                    -337 -159 -154 -264 -316 -320 -493 -72 -228 -134 -778 -134 -1200 0 -408 61
                    -964 131 -1191 50 -162 149 -322 290 -469 186 -192 409 -322 637 -370 84 -17
                    346 -50 567 -70 93 -9 170 -17 171 -18 73 -115 333 -494 407 -594 156 -209
                    224 -258 357 -258 88 0 144 22 211 81 57 52 216 267 402 545 l152 227 60 6
                    c33 3 132 13 220 22 227 22 430 51 521 75 106 27 279 112 387 189 104 75 264
                    238 329 335 61 92 121 214 147 299 70 227 131 783 131 1191 0 411 -61 964
                    -131 1190 -54 176 -166 350 -323 503 -146 142 -311 246 -476 302 -309 104
                    -1378 175 -2100 139z m891 -379 c537 -27 961 -76 1103 -127 251 -91 489 -340
                    550 -578 95 -371 131 -1072 81 -1575 -17 -168 -57 -429 -81 -524 -52 -203
                    -241 -425 -454 -535 -146 -75 -250 -95 -743 -141 -322 -30 -333 -37 -541 -350
                    -168 -254 -323 -470 -336 -470 -13 0 -168 216 -336 470 -216 325 -210 321
                    -634 360 -213 19 -422 49 -498 70 -275 77 -539 336 -606 595 -60 237 -106 689
                    -106 1055 0 355 46 810 106 1045 61 237 300 488 550 578 259 93 1287 160 1945
                    127z"/>
                <path data-parent-id={dataParentId} d="M2493 4249 c-108 -14 -185 -37 -290 -89 -179 -88 -337 -264 -420
                    -467 -49 -120 -56 -205 -20 -265 52 -90 167 -119 258 -66 49 29 70 63 110 176
                    31 88 108 194 181 247 104 77 250 107 412 86 156 -21 250 -71 313 -165 135
                    -199 25 -409 -303 -580 -131 -68 -231 -171 -294 -301 -59 -121 -74 -190 -74
                    -336 -1 -120 0 -127 27 -167 78 -118 264 -107 326 20 16 34 21 63 21 124 0 95
                    18 163 61 227 31 47 45 58 202 150 242 143 398 328 453 537 22 84 22 225 -1
                    310 -38 147 -134 299 -243 385 -179 141 -457 208 -719 174z"/>
                <path data-parent-id={dataParentId} d="M2385 1915 c-49 -17 -90 -62 -104 -112 -14 -50 -14 -277 0 -328 14
                    -51 65 -100 118 -115 23 -6 96 -11 161 -11 65 0 138 5 161 11 53 15 104 64
                    118 115 14 52 14 278 0 330 -14 50 -64 100 -114 114 -58 16 -291 13 -340 -4z"/>
            </g>
        </svg>
    )
}
export default AskQuestion