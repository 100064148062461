import { useSelector } from "react-redux"

import AccountPassword from "./AccountPassword"
import SocialAccount from "./SocialAccount"

const AccountAndSecurity = ({ isSocial }) => {
    const { user } = useSelector((state) => state.auth)
    return (
        <div className="bg-white text-start p-4 sm:p-5 shadow-card5 rounded-lg">
            <div className="font-medium py-3 border-b border-[#EFEEEE99]">
                <span className="text-versich-dark-blue text-xl">Account and Security</span>
            </div>
            <div className="text-versich-dark-blue my-5 space-y-5">
                {
                    isSocial
                        ? <SocialAccount />
                        : <>
                            <div className="text-sm">
                                <div className="flex max-smallest:flex-col smallest:items-start smallest:justify-between gap-x-3 gap-y-1.5">
                                    <span className="font-medium opacity-80">Email</span>
                                </div>
                                <div className="text-versich-dark-blue text-sm mt-2.5" style={{ overflowWrap: "anywhere" }}>
                                    <div className="font-semibold">{user.email}</div>
                                </div>
                            </div>
                            <AccountPassword />
                        </>
                }
            </div>
        </div>
    )
}
export default AccountAndSecurity