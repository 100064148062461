import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../../../../api"
import apiErrorParser from "../../../../utils/apiErrorParser"

export const allExpertServiceAsync = createAsyncThunk("service/FT/FPAR/allExpertService", async (params) => {
    try {
        const { data } = await api.FPandARevolutionServicesFT(params)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const allChoiceOfToolAsync = createAsyncThunk("service/FT/FPAR/allChoiceOfTool", async (params) => {
    try {
        const { data } = await api.FPandARevolutionChoiceOfToolsFT(params)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const createRequestAsync = createAsyncThunk("account/FT/FPAR/createRequest",
    async ({
        user, businessSize, services, tools, projectDuration, otherProjectDuration, paymentType, currency, budget, otherBudget,
        clientEmail, clientName, description, token
    }) => {
        try {
            const reqData = {
                businessSizeId: businessSize,
                serviceIds: services,
                toolsIds: tools,
                paymentTypeId: paymentType,
                currencyId: currency,
                estimatedBudgetId: budget,
                otherEstimatedBudget: otherBudget,
                projectDurationId: projectDuration,
                otherProjectDuration,
                clientEmail,
                clientName,
                description,
                csrfmiddlewaretoken: token
            }
            const { data } = user ? await api.FPandARevolutionCreateRequestFT(reqData) : await api.FPandARevolutionCreateRequestAsGuestFT(reqData)
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })

export const getRequestAsync = createAsyncThunk("account/FT/FPAR/getRequest", async (refId) => {
    try {
        const { data } = await api.FPandARevolutionGetRequestFT(refId)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const getRequestTitleAsync = createAsyncThunk("account/FT/FPAR/getRequestTitle", async (refId) => {
    try {
        const { data } = await api.FPandARevolutionGetRequestTitleFT(refId)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})