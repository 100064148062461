import threeUsersIcon from "../../../assets/icons/dashboard/3-users.svg"
import activityIcon from "../../../assets/icons/dashboard/activity.svg"
import ticketStarIcon from "../../../assets/icons/dashboard/ticket-star.svg"

const OtherDetails = ({ data }) => {
    return (
        <div className="bg-white text-start p-4 sm:p-5 shadow-card4 rounded-lg">
            <div className="text-versich-dark-blue font-bold pb-4 border-b border-[#EDF0F5]">Other Details</div>
            <div className="mt-5 p-1 space-y-6">
                <div className="flex gap-[26px]">
                    <div>
                        <div className="bg-brandeis-blue/[0.08] inline-flex items-center justify-center min-w-[43px] min-h-[43px] rounded-[10px]">
                            <img className="w-6 h-6" src={threeUsersIcon} alt="" />
                        </div>
                    </div>
                    <div className="text-versich-dark-blue text-sm">
                        <p className="font-semibold mb-[3px]">Total Employees</p>
                        <div>{data.totalEmployees.title}</div>
                    </div>
                </div>
                <div className="flex gap-[26px]">
                    <div>
                        <div className="bg-brandeis-blue/[0.08] inline-flex items-center justify-center min-w-[43px] min-h-[43px] rounded-[10px]">
                            <img className="w-6 h-6" src={activityIcon} alt="" />
                        </div>
                    </div>
                    <div className="text-versich-dark-blue text-sm">
                        <p className="font-semibold mb-[3px]">Minimum Project Size</p>
                        <div>{data.minimumProjectSize?.title || "-"}</div>
                    </div>
                </div>
                <div className="flex gap-[26px]">
                    <div>
                        <div className="bg-brandeis-blue/[0.08] inline-flex items-center justify-center min-w-[43px] min-h-[43px] rounded-[10px]">
                            <img className="w-6 h-6" src={ticketStarIcon} alt="" />
                        </div>
                    </div>
                    <div className="text-versich-dark-blue text-sm">
                        <p className="font-semibold mb-[3px]">Average Hourly Rate</p>
                        <div>{data.averageHourlyRate?.title || "-"}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default OtherDetails