import { useSelector, useDispatch } from "react-redux"
import { useState, useEffect } from "react"
import { MdOutlineUpdate } from "react-icons/md"

import noReviewsIcon from "../../../../assets/images/no-reviews.png"
import ReviewStars from "./components/ReviewStars"
import AskForReview from "./AskForReview"
import AddReview from "./AddReview"
import ReviewCard from "./components/ReviewCard"
import { getReviewAndReferencesAsync } from "../../../../slices/reviewAndReferences"
import { BUSINESS } from "../../../../assets/constants"

const ReviewProgressBar = ({ rating, total }) => {
    const progress = rating === 0 || total === 0 ? 0 : (rating / total) * 100
    return (
        <div className="bg-[#FFF9F3] flex-1 h-3 rounded-[17px] overflow-hidden">
            <div className="bg-[#EFA005] h-full rounded-full" style={{ width: `${progress}%` }} />
        </div>
    )
}

const Reviews = ({ data }) => {
    const { user } = useSelector(state => state.auth)
    const [reviews, setReviews] = useState(null)
    const [reviewsUpdated, setReviewsUpdated] = useState(false)

    // Load reviews
    const dispatch = useDispatch()
    const getReviews = () => {
        dispatch(getReviewAndReferencesAsync())
            .unwrap()
            .then(data => {
                setReviews(data.payload)
            })
            .catch(() => { })
    }
    useEffect(() => {
        data
            ? setReviews(data)
            : getReviews()
    }, [])

    // Re-load review
    useEffect(() => {
        if (reviewsUpdated) {
            getReviews()
            setReviewsUpdated(false)
        }
    }, [reviewsUpdated])

    // Premium Plan check
    const businessUserHasPremiumPlan = !data && user?.accountType === BUSINESS && user.plan && user.plan.name === "premium" && user.plan.status === "active"
    return (
        <div className="bg-white text-start p-4 sm:p-5 shadow-card4 rounded-lg">
            <div className="text-versich-dark-blue flex justify-between gap-4 pb-4 border-b border-[#EDF0F5]">
                <span className="font-bold">Reviews</span>
                {!data && <div className="flex">
                    <AskForReview reloadReviews={setReviewsUpdated} />
                    <AddReview reloadReviews={setReviewsUpdated} />
                </div>}
            </div>
            {reviews &&
                <div className="grid md:grid-cols-3 gap-x-6 gap-y-8 mt-5">
                    <div>
                        <div className="bg-[#F6F9FF] text-[#424242] flex flex-col items-center justify-center gap-2 py-8 px-6 rounded-xl">
                            <div className="text-[24px] xl:text-[32px] font-bold p-1">{reviews.average.toFixed(1)}/5.0</div>
                            <div className="inline-flex flex-wrap items-center justify-center gap-2">
                                <ReviewStars average={reviews.average} />
                                <p className="text-[12px] xl:text-[18px] font-semibold">({reviews.total} reviews)</p>
                            </div>
                        </div>
                        <div className="my-5 space-y-[15px]">
                            <div className="flex items-center gap-[9px]">
                                <ReviewStars average={5} />
                                <ReviewProgressBar rating={reviews.rating["5star"]} total={reviews.total} />
                            </div>
                            <div className="flex items-center gap-[9px]">
                                <ReviewStars average={4} />
                                <ReviewProgressBar rating={reviews.rating["4star"]} total={reviews.total} />
                            </div>
                            <div className="flex items-center gap-[9px]">
                                <ReviewStars average={3} />
                                <ReviewProgressBar rating={reviews.rating["3star"]} total={reviews.total} />
                            </div>
                            <div className="flex items-center gap-[9px]">
                                <ReviewStars average={2} />
                                <ReviewProgressBar rating={reviews.rating["2star"]} total={reviews.total} />
                            </div>
                            <div className="flex items-center gap-[9px]">
                                <ReviewStars average={1} />
                                <ReviewProgressBar rating={reviews.rating["1star"]} total={reviews.total} />
                            </div>
                        </div>
                    </div>
                    <div className="text-[#212121] md:col-span-2 space-y-[3px]">
                        {reviews.references.length > 0
                            ? reviews.references.map((ref, index) => (
                                <div className="text-sm space-y-3 max-sm404:px-0 p-4 border-b border-[#EEEEEE] last-of-type:border-b-0" key={index}>
                                    {ref.ratings
                                        ? <ReviewCard data={ref} hasVisibilityToggle={businessUserHasPremiumPlan} privatePage={!data} reloadReviews={setReviewsUpdated} key={index} />
                                        : <>
                                            <div className="font-bold mb-[3px]">{ref.name}</div>
                                            <div className="text-versich-light-gray flex items-center gap-1">
                                                <MdOutlineUpdate />
                                                <span>Your review request is awaiting response</span>
                                            </div>
                                        </>
                                    }
                                </div>
                            ))
                            : <div className="h-full flex items-center justify-center p-4">
                                <img className="w-[180px] opacity-60" src={noReviewsIcon} alt="no reviews" />
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    )
}
export default Reviews