import { useState, useEffect } from "react"
import { Datepicker } from "flowbite-react"

const DatePickerFieldInput = ({ label, name, value, placeholder, setPickedDate, required, error }) => {
    const [date, setDate] = useState(new Date())

    // Default value
    useEffect(() => {
        // if (value) {
        //     setPickedDate(value)
        // }
        !value && date && setPickedDate(date)
    }, [])

    useEffect(() => {
        if (value) {
            let d = new Date(value)
            d instanceof Date && setDate(d)
        }
    }, [value])
    return (
        <div className="space-y-2 text-start">
            {label && <label htmlFor={name} className="text-versich-dark-blue text-base font-medium flex">{label}{required && <span className="text-red-500 ml-1">*</span>}</label>}
            <div className={error ? "[&_input]:border-red-500" : ""}>
                <Datepicker
                    theme={customTheme}
                    placeholder={placeholder}
                    value={date}
                    onChange={date => setPickedDate(date)}
                />
            </div>
            {error &&
                <div className="text-sm text-red-500">{required}</div>
            }
        </div>
    )
}
const customTheme = {
    "root": {
        "input": {
            "field": {
                "input": {
                    "sizes": {
                        "md": "text-sm font-medium py-2.5 px-3 pl-10",
                    },
                    "colors": {
                        "gray": "bg-white text-black border border-gray-300 shadow-input outline-none focus-visible:border-versich-blue focus:shadow-inputFocus",
                    },
                },
            },
        },
    },
    "popup": {
        "root": {
            "inner": "inline-block rounded-lg bg-white p-4 shadow-form"
        },
        "header": {
            "selectors": {
                "button": {
                    "base": "rounded-lg bg-white px-5 py-2.5 text-sm font-semibold text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200",
                }
            }
        },
        "footer": {
            "button": {
                "base": "w-full rounded-lg px-5 py-2 text-center text-sm font-medium",
                "today": "bg-versich-blue text-white hover:bg-versich-blue-hover",
                "clear": "border border-gray-300 bg-white text-gray-900 hover:bg-gray-100"
            }
        },
    },
    "views": {
        "days": {
            "items": {
                "item": {
                    "base": "block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9 text-gray-900 hover:bg-gray-100",
                    "selected": "bg-versich-blue text-white hover:bg-versich-blue-hover",
                }
            }
        },
        "months": {
            "items": {
                "item": {
                    "base": "block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9 text-gray-900 hover:bg-gray-100",
                    "selected": "bg-versich-blue text-white hover:bg-versich-blue-hover",
                }
            }
        },
        "years": {
            "items": {
                "item": {
                    "base": "block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9 text-gray-900 hover:bg-gray-100",
                    "selected": "bg-versich-blue text-white hover:bg-versich-blue-hover",
                }
            }
        },
        "decades": {
            "items": {
                "item": {
                    "base": "block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9 text-gray-900 hover:bg-gray-100",
                    "selected": "bg-versich-blue text-white hover:bg-versich-blue-hover",
                }
            }
        },
    }
}

export default DatePickerFieldInput