import { useFormContext } from "react-hook-form"
import { useEffect } from "react"
import { IoIosArrowDown } from "react-icons/io"

const DropdownField = ({ label, options, value, placeholder, name, rules, onChange }) => {
    const { register, formState, setValue, clearErrors } = useFormContext()
    const { errors } = formState

    // Remove errors on value change
    useEffect(() => {
        clearErrors(name)
    }, [value])

    useEffect(() => {
        setValue(name, value)
    }, [value, name])
    return (
        <div className="space-y-2 text-start">
            {label && <label htmlFor={name} className="text-charcoal text-sm flex">{label}{rules?.required && <span className="text-red-500 ml-1">*</span>}</label>}
            <div className="relative">
                <select
                    id={name}
                    className={"bg-white text-sm w-full h-10 py-2.5 px-3 border-[1px] focus-visible:border-versich-blue shadow-input focus:shadow-inputFocus outline-none rounded-lg appearance-none "
                        + (errors[name] ? "border-red-500" : "border-gray-300")
                    }
                    name={name}
                    {...register(name, rules)}
                    value={value}
                    onChange={onChange}
                >
                    <option value="" {...rules?.required ? {disabled: true, hidden: true} : null}>{placeholder}</option>
                    {options.map((option) => (
                        <option className="w-full px-3 pr-8 appearance-none focus:outline-none" value={option.uid} key={option.uid}>{option.title}</option>
                    ))}
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                    <IoIosArrowDown className="w-5 h-5 text-gray-400" />
                </div>
            </div>
            {errors[name] &&
                <div className="text-sm text-red-500">{errors[name].message}</div>
            }
        </div>
    )
}
export default DropdownField
