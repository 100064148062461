import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { IoMdRadioButtonOff, IoMdRadioButtonOn } from "react-icons/io"

import budgetEstimateIcon from "../../../../../assets/icons/request/budget-estimate.png"
import NewRequestLayout from "../NewRequestLayout"
import RadioOptions from "./RadioOptions"
import { allPaymentTypeAsync, allCurrencyAsync, allEstimatedBudgetAsync } from "../../../../../slices/service"

const PaymentType = ({ options, value, setValue }) => {
    return (
        <div className="mx-1 md:mx-2">
            <p className="text-sm pb-3">Select Payment Type</p>
            <div className="flex items-center gap-2 pb-2.5">
                {options.map((option) => (
                    <label
                        key={option.uid}
                        className="flex items-center px-1 py-2 md:p-2 cursor-pointer group"
                        onClick={() => setValue(option.uid)}
                    >
                        <input className="appearance-none" name="choice" type="radio" value={option.uid} />
                        {value === option.uid
                            ? <IoMdRadioButtonOn className="fill-versich-blue-hover min-w-4" />
                            : <IoMdRadioButtonOff className="text-versich-dark-blue group-hover:fill-versich-blue-hover min-w-4" />
                        }
                        <span className="text-versich-dark-blue max-md:text-sm w-full ps-2 group-hover:text-versich-blue-hover transition-all">{option.title}</span>
                    </label>
                ))}
            </div>
        </div>
    )
}

const Budget = ({ totalForms, formPosition, updateFormPosition, paymentType, setPaymentType, setCurrency, setBudget, setOtherBudget, goBack }) => {
    const [paymentTypes, setPaymentTypes] = useState([])
    const [currencies, setCurrencies] = useState([])
    const [value, setValue] = useState("")
    const [otherValue, setOtherValue] = useState("")
    const [error, setError] = useState(false)
    const [otherInputError, setOtherInputError] = useState(false)

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(allPaymentTypeAsync())
            .unwrap()
            .then((data) => {
                setPaymentTypes(data.payload)

                // Set default paymenttype
                data.payload && data.payload.length > 0
                    && setPaymentType(data.payload[0].uid)
            })
        dispatch(allCurrencyAsync())
            .unwrap()
            .then((data) => {
                setCurrencies(data.payload)

                // Set default currency
                data.payload && data.payload.length > 0
                    && setCurrency(data.payload[0].uid)
            })
    }, [])

    const handleContinue = () => {
        if (value === "other") {
            if (otherValue) {
                setBudget(value)
                setOtherBudget(otherValue)
            }
            else {
                setOtherInputError(true)
                setTimeout(() => {
                    setOtherInputError(false)
                }, 2000)
            }
        }
        else if (value) {
            setBudget(value)
        }
        else {
            setError(true)
            setTimeout(() => {
                setError(false)
            }, 2000)
        }
    }
    return (
        <NewRequestLayout
            icon={budgetEstimateIcon}
            description="Your budget size"
            totalForms={totalForms} currentForm={formPosition}
            handleBackClick={goBack}
            handleContinueClick={handleContinue}
        >
            <div className="relative">
                <p className="text-versich-dark-blue text-lg font-semibold pb-3">What is your estimated budget for this project?</p>
                <PaymentType options={paymentTypes} value={paymentType} setValue={setPaymentType} />
                <div className="mx-1 md:mx-2">
                    <p className="text-sm pb-3">Select Currency</p>
                    <select className="bg-white text-sm w-full h-10 mb-4 px-3 pr-8 appearance-none border-[1px] border-versich-border hover:border-versich-blue focus:outline-none rounded-lg cursor-pointer" onChange={e => setCurrency(e.target.value)}>
                        {currencies.map(currency => (
                            <option value={currency.uid} key={currency.uid}>{currency.title}</option>
                        ))}
                    </select>
                </div>
                <RadioOptions
                    actionAsync={allEstimatedBudgetAsync}
                    updateFormPosition={updateFormPosition}
                    value={value}
                    setValue={setValue}
                    error={error}
                    setOtherValue={setOtherValue}
                    otherInputError={otherInputError}
                />
            </div>
        </NewRequestLayout>
    )
}
export default Budget
