import { useSelector, useDispatch } from "react-redux"
import { useForm, FormProvider } from "react-hook-form"
import { useState, useEffect } from "react"

import curvedEditIcon from "../../../../assets/svgs/curved-edit.svg"
import DialogBox, { closeDialog, getDialog, openDialog } from "../../../DialogBox"
import FormLoader from "../../../loader/FormLoader"
import Close from "../../../svgs/Close"
import CSRFTokenField from "../../../Inputs/CSRFTokenField"
import CheckboxBadgeOptions from "../../../Inputs/CheckboxBadgeOptions"
import TextAreaField from "../../../Inputs/TextAreaField"
import DropdownField from "../../../Inputs/DropdownField"
import toastify from "../../../../utils/toastify"
import {
    businessAboutAllServiceAsync,
    businessAboutAllTotalEmployeeAsync,
    businessAboutAllMinimumProjectSizeAsync,
    businessAboutAllAverageHourlyRateAsync,
    businessAboutUpdateAsync
} from "../../../../slices/business/about"
import { TOASTTYPE_ERROR } from "../../../../assets/constants"

const AboutBusiness = () => {
    const { user } = useSelector((state) => state.auth)
    const methods = useForm()

    const [csrfToken, setCsrfToken] = useState("")
    const [services, setServices] = useState([])
    const [tagline, setTagline] = useState("")
    const [description, setDescription] = useState("")
    const [totalEmployeeOptions, setTotalEmployeeOptions] = useState([])
    const [totalEmployees, setTotalEmployees] = useState("")
    const [minProjectSizeOptions, setMinProjectSizeOptions] = useState([])
    const [minProjectSize, setMinProjectSize] = useState("")
    const [averageHourlyRateOptions, setAverageHourlyRateOptions] = useState([])
    const [averageHourlyRate, setAverageHourlyRate] = useState("")
    const [serviceError, setServiceError] = useState(false)
    const [otherServiceValue, setOtherServiceValue] = useState("")
    const [otherServiceInputError, setOtherServiceInputError] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    // Load dropdown values
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(businessAboutAllTotalEmployeeAsync(`?orderby=id`))
            .unwrap()
            .then((data) => {
                setTotalEmployeeOptions(data.payload)
            })
        dispatch(businessAboutAllMinimumProjectSizeAsync(`?orderby=id`))
            .unwrap()
            .then((data) => {
                setMinProjectSizeOptions(data.payload)
            })
        dispatch(businessAboutAllAverageHourlyRateAsync(`?orderby=id`))
            .unwrap()
            .then((data) => {
                setAverageHourlyRateOptions(data.payload)
            })
    }, [])

    // Pre-fill Form
    const resetForm = () => {
        setServices(user.business.about.services)
        if (user.business.about.otherService) {
            setOtherServiceValue(user.business.about.otherService)
        }
        setTagline(user.business.about.tagline)
        setDescription(user.business.about.description)
        setTotalEmployees(user.business.about.totalEmployees?.uid || "")
        setMinProjectSize(user.business.about.minimumProjectSize?.uid || "")
        setAverageHourlyRate(user.business.about.averageHourlyRate?.uid || "")
    }
    useEffect(() => {
        resetForm()
    }, [user])

    // Close dialog
    const customCloseDialog = (e) => {
        resetForm()
        closeDialog(e)
    }

    // Handle OtherInput change
    const handleOtherInputChange = (e) => {
        setOtherServiceValue(e.target.value)
        setOtherServiceInputError(false)
    }

    const customHandleSubmit = (e) => {
        e.preventDefault()
        if (services.length === 0) {
            setServiceError(true)
        }
        else setOtherServiceInputError(otherServiceValue === "")
        methods.handleSubmit(onSubmit)(e)
    }
    const onSubmit = async (data, event) => {
        try {
            const isValid = await methods.trigger()
            if (!isValid) {
                toastify("Form validation failed", TOASTTYPE_ERROR)
                return methods.reset()
            }
            const formData = {
                services,
                ...(otherServiceValue && { otherService: otherServiceValue }),
                tagline, description,
                totalEmployees, minProjectSize, averageHourlyRate,
                token: csrfToken,
            }
            setLoading(true)
            await dispatch(businessAboutUpdateAsync(formData))
                .unwrap()
                .then(() => {
                    const dialog = getDialog(event.target)
                    if (dialog) {
                        closeDialog(null, dialog)
                    }
                    methods.reset()
                    return setLoading(false)
                })
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    return setErrorMsg(data.message)
                })
                .finally(() => setLoading(false))
        } catch (error) {
            console.error("Form submission error:", error)
        }
    }
    return (
        <div className="bg-white text-start p-4 sm:p-5 shadow-card5 rounded-lg">
            <div className="font-medium flex max-sm401:flex-col sm401:justify-between gap-x-3 gap-y-1.5 py-3 border-b border-[#EFEEEE99]">
                <span className="text-versich-dark-blue text-xl">About Business</span>
                <button className="inline-flex items-center gap-1 hover:opacity-80" onClick={openDialog} data-parent-id={aboutEditDialog}>
                    <img className="w-[18px] h-[18px]" src={curvedEditIcon} alt="" data-parent-id={aboutEditDialog} />
                    <span className="text-versich-light-blue text-xs" data-parent-id={aboutEditDialog}>Edit</span>
                </button>
            </div>
            <div className="text-versich-dark-blue my-5 space-y-8">
                <div className="text-sm">
                    <p className="font-medium opacity-80">What does your business do?</p>
                    <div className="flex gap-1 flex-wrap mt-2.5">
                        {user.business.about.services.map(service => (
                            <span className="bg-brandeis-blue/[0.08] inline-block py-1.5 px-2.5 rounded-full" key={service.uid}>
                                {service.uid === "other" && user.business.about.otherService ? user.business.about.otherService : service.title}
                            </span>
                        ))}
                    </div>
                </div>
                <div className="text-sm">
                    <p className="font-medium opacity-80 mb-[7px]">Tagline</p>
                    <div className="font-semibold">{user.business.about.tagline}</div>
                </div>
                <div className="text-sm">
                    <p className="font-medium opacity-80 mb-[7px]">Description</p>
                    <div className="font-semibold space-y-1.5">{user.business.about.description.split("\n").map((desc, index) => <p key={index}>{desc}</p>)}</div>
                </div>
                <div className="grid grid-cols-1 sm:max-md:grid-cols-2 lg900:max-xl1080:grid-cols-2 xl:grid-cols-2 gap-x-3 gap-y-6">
                    <div className="text-sm">
                        <p className="font-medium opacity-80 mb-[7px]">Total Employees</p>
                        <div className="font-semibold">{user.business.about.totalEmployees.title}</div>
                    </div>
                    <div className="text-sm">
                        <p className="font-medium opacity-80 mb-[7px]">Average Hourly Rate</p>
                        <div className="font-semibold">{user.business.about.averageHourlyRate?.title || "-"}</div>
                    </div>
                </div>
                <div className="text-sm">
                    <p className="font-medium opacity-80 mb-[7px]">Minimum Project Size</p>
                    <div className="font-semibold">{user.business.about.minimumProjectSize?.title || "-"}</div>
                </div>
            </div>
            <DialogBox
                id={aboutEditDialog}
                className="backdrop:bg-[#1717253D] backdrop:backdrop-blur-sm max-md:max-w-lg lg:w-3/4 2xl:!w-2/3 !max-w-4xl !overflow-visible"
                toastStacked={false}
                hideClose={true}
                customCloseEvent={customCloseDialog}
            >
                <div className="space-y-[34px] p-2.5 md:p-4">
                    <div className="flex gap-1.5">
                        <div className="text-versich-dark-blue flex-1">
                            <div className="text-xl font-semibold">About Business</div>
                            <div className="text-sm mt-0.5">Manage your business about</div>
                        </div>
                        <div id="dialog_closebtn" className="text-right">
                            <button type="button" className="m-1 p-1" onClick={customCloseDialog} data-parent-id={aboutEditDialog}>
                                <Close className="w-4 h-4" />
                            </button>
                        </div>
                    </div>
                    <FormProvider {...methods}>
                        <form className="space-y-[34px]" method="dialog" onSubmit={customHandleSubmit} data-parent-id={aboutEditDialog}>
                            <div className="space-y-5 overflow-auto h-[50vh]">
                                <div className="relative">
                                    <p className="text-charcoal text-sm text-left pb-2">What does your business do? <span className="text-red-500 ml-1">*</span></p>
                                    <CheckboxBadgeOptions
                                        actionAsync={businessAboutAllServiceAsync}
                                        values={services}
                                        setValues={setServices}
                                        error={serviceError}
                                        setError={setServiceError}
                                        otherValue={otherServiceValue}
                                        onOtherInputChange={handleOtherInputChange}
                                        otherInputError={otherServiceInputError}
                                    />
                                </div>
                                <TextAreaField
                                    name="tagline"
                                    label="Business Tagline"
                                    placeholder="e.g. Technology consulting with Excellence, Marketing business with digital accuracy"
                                    onChange={(e) => setTagline(e.target.value)}
                                    defaultValue={tagline}
                                    rules={{ required: "Tagline is required" }}
                                />
                                <TextAreaField
                                    name="description"
                                    label="Description"
                                    placeholder="Write here..."
                                    onChange={(e) => setDescription(e.target.value)}
                                    defaultValue={description}
                                    rules={{ required: "Description is required" }}
                                />
                                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-x-3 gap-5">
                                    <DropdownField
                                        name="totalEmployees"
                                        label="Total Employees"
                                        options={totalEmployeeOptions}
                                        value={totalEmployees}
                                        placeholder="Select"
                                        rules={{ required: "Total Employees is required" }}
                                        onChange={(e) => setTotalEmployees(e.target.value)}
                                    />
                                    <DropdownField
                                        name="minProjectSize"
                                        label="Minimum Project Size"
                                        options={minProjectSizeOptions}
                                        value={minProjectSize}
                                        placeholder="Select"
                                        onChange={(e) => setMinProjectSize(e.target.value)}
                                    />
                                    <div className="max-xl:lg:col-span-2">
                                        <DropdownField
                                            name="averageHourlyRate"
                                            label="Average Hourly Rate"
                                            options={averageHourlyRateOptions}
                                            value={averageHourlyRate}
                                            placeholder="Select"
                                            onChange={(e) => setAverageHourlyRate(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                            {errorMsg && <div className="text-sm text-red-500 text-left mt-8 mb-4">{errorMsg}</div>}
                            <div className="text-sm font-semibold w-full flex max-sm401:flex-col justify-end gap-3 md:gap-5">
                                <button className="bg-white hover:opacity-80 text-versich-light-blue border border-versich-light-blue py-2.5 md:py-3 px-8 md:px-10 rounded-lg" type="button" onClick={customCloseDialog} data-parent-id={aboutEditDialog}>Cancel</button>
                                <button className="bg-versich-blue hover:bg-versich-blue-hover disabled:bg-versich-blue-hover text-white disabled:opacity-70 py-2.5 md:py-3 px-8 md:px-10 rounded-lg disabled:cursor-not-allowed" type="submit">Update</button>
                            </div>
                        </form>
                    </FormProvider>
                </div>
                {loading && <FormLoader />}
            </DialogBox>
        </div>
    )
}
const aboutEditDialog = "about-edit-dialog"
export default AboutBusiness