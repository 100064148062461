import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import ServicesList from "../../../assets/ServicesList"
import WebsiteRequirement from "./WebDesignAndDevelopment/WebsiteRequirement"
import WebsiteNeed from "./WebDesignAndDevelopment/WebsiteNeed"
import MobileProjectType from "./MobileApp/MobileProjectType"
import MobileAppPlatform from "./MobileApp/MobileAppPlatform"
import MobileProjectNeed from "./MobileApp/MobileProjectNeed"
import BusinessServiceNeed from "./DataAndAnalytics/BusinessServiceNeed"
import PersonalAssistanceNeed from "./DataAndAnalytics/PersonalAssistanceNeed"
import SoftwareType from "./SoftwareDevelopment/SoftwareType"
import OperatingSystem from "./SoftwareDevelopment/OperatingSystem"
import MoreService from "./FinanceTransformation/MoreService"
import FPandARevolutionService from "./FinanceTransformation/FPandARevolutionServices"
import FinancialReportingAndAdvancedAnalyticsService from "./FinanceTransformation/FinancialReportingAndAdvancedAnalytics"
import DigitalTransformationService from "./FinanceTransformation/DigitalTransformation"
import Expert from "./MachineLearningAndAI/Expert"
import { dashboardPath, newRequestPath } from "../../../assets/constants"

const SubServices = ({ id, importantOnly }) => {
    const { user } = useSelector(state => state.auth)
    const navigate = useNavigate()
    const navigateToRequestForm = (uid, subPath) => {
        const requestFormPath = newRequestPath + uid
        if (!user || (user && user.accountType === "client")) {
            return navigate(`${requestFormPath}/${subPath}`)
        }
        navigate(dashboardPath)
    }

    switch (id) {
        case ServicesList[0].uid:   // Web Design and Development
            return (
                <div className="flex-grow mt-4">
                    <WebsiteRequirement navigateToRequestForm={(path) => navigateToRequestForm(id, path)} />
                    {!importantOnly && <WebsiteNeed navigateToRequestForm={(path) => navigateToRequestForm(id, path)} />}
                </div>
            )
        case ServicesList[1].uid:   // Mobile App
            return (
                <div className="flex-grow mt-4">
                    <MobileProjectType navigateToRequestForm={(path) => navigateToRequestForm(id, path)} />
                    {!importantOnly && (<>
                        <MobileAppPlatform navigateToRequestForm={(path) => navigateToRequestForm(id, path)} />
                        <MobileProjectNeed navigateToRequestForm={(path) => navigateToRequestForm(id, path)} />
                    </>)}
                </div>
            )
        case ServicesList[2].uid:   // Data and Analytics
            return (
                <div className="flex-grow mt-4">
                    <BusinessServiceNeed navigateToRequestForm={(path) => navigateToRequestForm(id, path)} />
                    {!importantOnly && <PersonalAssistanceNeed navigateToRequestForm={(path) => navigateToRequestForm(id, path)} />}
                </div>
            )
        case ServicesList[3].uid:   // Software Development
            return (
                <div className="flex-grow mt-4">
                    <SoftwareType navigateToRequestForm={(path) => navigateToRequestForm(id, path)} />
                    {!importantOnly && <OperatingSystem navigateToRequestForm={(path) => navigateToRequestForm(id, path)} />}
                </div>
            )
        case ServicesList[4].uid:   // Finance Transformation
            return (
                <div className="flex-grow max-sm:mt-2">
                    <MoreService data={ServicesList[4].subServices} navigateToRequestForm={(path) => navigateToRequestForm(id, path)} />
                    {!importantOnly && (<>
                        <FPandARevolutionService navigateToRequestForm={(path) => navigateToRequestForm(id, path)} />
                        <FinancialReportingAndAdvancedAnalyticsService navigateToRequestForm={(path) => navigateToRequestForm(id, path)} />
                        <DigitalTransformationService navigateToRequestForm={(path) => navigateToRequestForm(id, path)} />
                    </>)}
                </div>
            )
        case ServicesList[5].uid:   // Machine Learning and AI
            return (
                <div className="flex-grow mt-4">
                    <Expert navigateToRequestForm={(path) => navigateToRequestForm(id, path)} />
                </div>
            )
        default:
            return null
    }
}
export default SubServices