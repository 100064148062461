import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../../../../api"
import apiErrorParser from "../../../../utils/apiErrorParser"

export const allChoiceOfToolAsync = createAsyncThunk("service/FT/enterprise/allChoiceOfTool", async (params) => {
    try {
        const { data } = await api.EnterpriseChoiceOfTools_FT(params)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const createRequestAsync = createAsyncThunk("account/FT/enterprise/createRequest",
    async ({
        user, businessSize, tools, projectDuration, otherProjectDuration, paymentType, currency, budget, otherBudget,
        clientEmail, clientName, description, token
    }) => {
        try {
            const reqData = {
                businessSizeId: businessSize,
                toolsIds: tools,
                paymentTypeId: paymentType,
                currencyId: currency,
                estimatedBudgetId: budget,
                otherEstimatedBudget: otherBudget,
                projectDurationId: projectDuration,
                otherProjectDuration,
                clientEmail,
                clientName,
                description,
                csrfmiddlewaretoken: token
            }
            const { data } = user ? await api.EnterpriseCreateRequest_FT(reqData) : await api.EnterpriseCreateRequestAsGuest_FT(reqData)
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })

export const getRequestAsync = createAsyncThunk("account/FT/enterprise/getRequest", async (refId) => {
    try {
        const { data } = await api.EnterpriseGetRequestFT(refId)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const getRequestTitleAsync = createAsyncThunk("account/FT/enterprise/getRequestTitle", async (refId) => {
    try {
        const { data } = await api.EnterpriseGetRequestTitleFT(refId)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})