import { useRef, useState, useEffect, useMemo } from "react"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import {
	ClassicEditor,
	Alignment,
	Autoformat,
	AutoImage,
	Autosave,
	BalloonToolbar,
	Base64UploadAdapter,
	BlockQuote,
	Bold,
	Code,
	CodeBlock,
	Essentials,
	FindAndReplace,
	FontBackgroundColor,
	FontColor,
	FontFamily,
	FontSize,
	GeneralHtmlSupport,
	Heading,
	Highlight,
	ImageBlock,
	ImageCaption,
	ImageInline,
	ImageInsert,
	ImageInsertViaUrl,
	ImageResize,
	ImageStyle,
	ImageTextAlternative,
	ImageToolbar,
	ImageUpload,
	Indent,
	IndentBlock,
	Italic,
	Link,
	LinkImage,
	List,
	ListProperties,
	MediaEmbed,
	Paragraph,
	PasteFromOffice,
	RemoveFormat,
	SpecialCharacters,
	SpecialCharactersArrows,
	SpecialCharactersCurrency,
	SpecialCharactersEssentials,
	SpecialCharactersLatin,
	SpecialCharactersMathematical,
	SpecialCharactersText,
	Strikethrough,
	Subscript,
	Superscript,
	Table,
	TableCaption,
	TableCellProperties,
	TableColumnResize,
	TableProperties,
	TableToolbar,
	TextTransformation,
	TodoList,
	Underline
} from "ckeditor5"
import "ckeditor5/ckeditor5.css"

import "./index.css"


const LICENSE_KEY = "GPL"

const RichTextField = ({ onChange, value, placeholder = "" }) => {
	const editorRef = useRef(null)
	const [isLayoutReady, setIsLayoutReady] = useState(false)

	useEffect(() => {
		setIsLayoutReady(true)

		return () => setIsLayoutReady(false)
	}, [])

	const { editorConfig } = useMemo(() => {
		if (!isLayoutReady) {
			return {}
		}

		return {
			editorConfig: {
				toolbar: {
					items: [
						"findAndReplace",
						"|",
						"heading",
						"|",
						"fontSize",
						"fontFamily",
						"fontColor",
						"fontBackgroundColor",
						"|",
						"bold",
						"italic",
						"underline",
						"strikethrough",
						"subscript",
						"superscript",
						"code",
						"removeFormat",
						"|",
						"specialCharacters",
						"link",
						"insertImage",
						"mediaEmbed",
						"insertTable",
						"highlight",
						"blockQuote",
						"codeBlock",
						"|",
						"alignment",
						"|",
						"bulletedList",
						"numberedList",
						"todoList",
						"outdent",
						"indent"
					],
					shouldNotGroupWhenFull: false
				},
				plugins: [
					Alignment,
					Autoformat,
					AutoImage,
					Autosave,
					BalloonToolbar,
					Base64UploadAdapter,
					BlockQuote,
					Bold,
					Code,
					CodeBlock,
					Essentials,
					FindAndReplace,
					FontBackgroundColor,
					FontColor,
					FontFamily,
					FontSize,
					GeneralHtmlSupport,
					Heading,
					Highlight,
					ImageBlock,
					ImageCaption,
					ImageInline,
					ImageInsert,
					ImageInsertViaUrl,
					ImageResize,
					ImageStyle,
					ImageTextAlternative,
					ImageToolbar,
					ImageUpload,
					Indent,
					IndentBlock,
					Italic,
					Link,
					LinkImage,
					List,
					ListProperties,
					MediaEmbed,
					Paragraph,
					PasteFromOffice,
					RemoveFormat,
					SpecialCharacters,
					SpecialCharactersArrows,
					SpecialCharactersCurrency,
					SpecialCharactersEssentials,
					SpecialCharactersLatin,
					SpecialCharactersMathematical,
					SpecialCharactersText,
					Strikethrough,
					Subscript,
					Superscript,
					Table,
					TableCaption,
					TableCellProperties,
					TableColumnResize,
					TableProperties,
					TableToolbar,
					TextTransformation,
					TodoList,
					Underline
				],
				balloonToolbar: ["bold", "italic", "|", "link", "insertImage", "|", "bulletedList", "numberedList"],
				fontFamily: {
					supportAllValues: true
				},
				fontSize: {
					options: [10, 12, 14, "default", 18, 20, 22],
					supportAllValues: true
				},
				heading: {
					options: [
						{
							model: "paragraph",
							title: "Paragraph",
							class: "ck-heading_paragraph"
						},
						{
							model: "heading1",
							view: "h1",
							title: "Heading 1",
							class: "ck-heading_heading1"
						},
						{
							model: "heading2",
							view: "h2",
							title: "Heading 2",
							class: "ck-heading_heading2"
						},
						{
							model: "heading3",
							view: "h3",
							title: "Heading 3",
							class: "ck-heading_heading3"
						},
						{
							model: "heading4",
							view: "h4",
							title: "Heading 4",
							class: "ck-heading_heading4"
						},
						{
							model: "heading5",
							view: "h5",
							title: "Heading 5",
							class: "ck-heading_heading5"
						},
						{
							model: "heading6",
							view: "h6",
							title: "Heading 6",
							class: "ck-heading_heading6"
						}
					]
				},
				htmlSupport: {
					allow: [
						{
							name: /^.*$/,
							styles: true,
							attributes: true,
							classes: true
						}
					]
				},
				image: {
					toolbar: [
						"toggleImageCaption",
						"imageTextAlternative",
						"|",
						"imageStyle:inline",
						"imageStyle:wrapText",
						"imageStyle:breakText",
						"|",
						"resizeImage"
					]
				},
				initialData: value,
				licenseKey: LICENSE_KEY,
				link: {
					addTargetToExternalLinks: true,
					defaultProtocol: "https://",
					decorators: {
						toggleDownloadable: {
							mode: "manual",
							label: "Downloadable",
							attributes: {
								download: "file"
							}
						}
					}
				},
				list: {
					properties: {
						styles: true,
						startIndex: true,
						reversed: true
					}
				},
				placeholder: placeholder,
				table: {
					contentToolbar: ["tableColumn", "tableRow", "mergeTableCells", "tableProperties", "tableCellProperties"]
				}
			}
		}
	}, [isLayoutReady])
	return (
		<div id="richtext-wrapper" className="w-full">
			{editorConfig
				&&
				<CKEditor
					editor={ClassicEditor}
					config={editorConfig}
					onReady={(editor) => {
						editorRef.current = editor
					}}
					onChange={() => {
						onChange(editorRef.current?.getData())
					}}
					data={value}
				/>}
		</div>
	)
}
export default RichTextField