import { createAsyncThunk } from "@reduxjs/toolkit"
import { format } from "date-fns"

import * as api from "../../api"
import { getUser } from "../../actions/auth"
import apiErrorParser from "../../utils/apiErrorParser"

export const freelancerServiceProjectAllProjectSizeAsync = createAsyncThunk("service/account/freelancerServiceProjectAllProjectSize", async (params) => {
    try {
        const { data } = await api.freelancerServiceProjectGetAllProjectSize(params)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const freelancerAddServiceProjectAsync = createAsyncThunk("account/freelancerAddServiceProject",
    async ({ serviceId, projectName, description, clientName, projectSizeId, projectStart, projectEnd, images, token }) => {
        try {
            const formData = new FormData()
            const formatToDate = (date) => {
                return format(date, "yyyy-MM-dd")
            }
            formData.append("serviceId", serviceId)
            formData.append("projectName", projectName)
            formData.append("description", description)
            formData.append("clientName", clientName)
            formData.append("projectSizeId", projectSizeId)
            formData.append("projectStart",
                formatToDate(projectStart)
            )
            formData.append("projectEnd",
                formatToDate(projectEnd)
            )
            images.forEach(img => {
                formData.append("images", img)
            })
            formData.append("csrfmiddlewaretoken", token)
            const { data } = await api.freelancerAddServiceProject(formData)
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })

export const freelancerUpdateServiceProjectAsync = createAsyncThunk("account/freelancerUpdateServiceProject",
    async ({ projectId, serviceId, projectName, description, clientName, projectSizeId, projectStart, projectEnd, images, urls, token }) => {
        try {
            const formData = new FormData()
            const formatToDate = (date) => {
                return format(date, "yyyy-MM-dd")
            }
            formData.append("serviceId", serviceId)
            formData.append("projectName", projectName)
            formData.append("description", description)
            formData.append("clientName", clientName)
            formData.append("projectSizeId", projectSizeId)
            formData.append("projectStart",
                formatToDate(projectStart)
            )
            formData.append("projectEnd",
                formatToDate(projectEnd)
            )
            images.forEach(img => {
                formData.append("images", img)
            })
            urls.forEach(url => {
                formData.append("urls", url)
            })
            formData.append("csrfmiddlewaretoken", token)
            const { data } = await api.freelancerUpdateServiceProject(projectId, formData)
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })

export const freelancerServiceDetailsSetAsync = createAsyncThunk("account/freelancerServiceDetailsSet",
    async ({ serviceDetails, token }, { dispatch }) => {
        try {
            const formData = new FormData()
            const formatToDate = (date) => {
                return format(date, "yyyy-MM-dd")
            }
            Object.entries(serviceDetails).forEach(([key, value]) => {
                formData.append("services", key)
                formData.append(`projectnames[${key}]`, value.projectname)
                formData.append(`clientnames[${key}]`, value.clientname)
                formData.append(`projectsizes[${key}]`, value.projectsize)
                formData.append(`projectstarts[${key}]`,
                    formatToDate(value.projectstartdate)
                )
                formData.append(`projectends[${key}]`,
                    formatToDate(value.projectenddate)
                )
                formData.append(`descriptions[${key}]`, value.description)
                value.images.forEach(img => {
                    formData.append(`images[${key}]`, img)
                })
            })
            formData.append("csrfmiddlewaretoken", token)
            const { data } = await api.freelancerServiceDetailsSet(formData)
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            await dispatch(getUser())
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })

export const allFreelancerServiceProjectAsync = createAsyncThunk("service/allFreelancerServiceProject", async (id) => {
    try {
        const { data } = await api.allFreelancerServiceProject(id)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const getFreelancerServiceProjectDetailsAsync = createAsyncThunk("service/getFreelancerServiceProjectDetails", async (projectId) => {
    try {
        const { data } = await api.getFreelancerServiceProjectDetails(projectId)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})