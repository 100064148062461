import { useSelector } from "react-redux"
import { useLocation, Navigate, Outlet } from "react-router-dom"

import { clientSignupPath, dashboardPath, profilePictureSetupPath } from "../../assets/constants"

const NotAuthRoutes = () => {
    const { isAuthenticated, user } = useSelector(state => state.auth)
    const location = useLocation()
    if (isAuthenticated && user !== null) {
        if (location.pathname === clientSignupPath) {
            return <Navigate to={profilePictureSetupPath} />
        }
        const path = new URLSearchParams(location.search).get("next") || dashboardPath
        return <Navigate to={path} replace />
    }
    else return <Outlet />
}
export default NotAuthRoutes