import profileIcon from "../../../assets/icons/dashboard/profile.svg"
import threeUsersIcon from "../../../assets/icons/dashboard/3-users.svg"
import callingIcon from "../../../assets/icons/dashboard/calling.svg"
import sendIcon from "../../../assets/icons/dashboard/send.svg"
import locationIcon from "../../../assets/icons/dashboard/location.svg"
import { SocialLink } from "../business/SocialMedia"

const About = ({ data, hideEmpty }) => {
    return (
        data.phone &&
        <div className="bg-white text-start p-4 sm:p-5 shadow-card4 rounded-lg">
            <div className="text-versich-dark-blue font-bold pb-4 border-b border-[#EDF0F5]">About</div>
            <div className="mt-5 p-1 space-y-6">
                <div className="grid grid-cols-1 lg900:max-xl1080:grid-cols-2 xl:grid-cols-2 gap-x-3 gap-y-6">
                    {((hideEmpty && data.companyName) || !hideEmpty) &&
                        <div className="flex gap-[26px]">
                            <div>
                                <div className="bg-brandeis-blue/[0.08] inline-flex items-center justify-center min-w-[43px] min-h-[43px] rounded-[10px]">
                                    <img className="w-6 h-6" src={profileIcon} alt="" />
                                </div>
                            </div>
                            <div className="text-versich-dark-blue text-sm">
                                <p className="font-semibold mb-[3px]">Company Name</p>
                                <div>{data.companyName || "-"}</div>
                            </div>
                        </div>
                    }
                    <div className="flex gap-[26px]">
                        <div>
                            <div className="bg-brandeis-blue/[0.08] inline-flex items-center justify-center min-w-[43px] min-h-[43px] rounded-[10px]">
                                <img className="w-6 h-6" src={threeUsersIcon} alt="" />
                            </div>
                        </div>
                        <div className="text-versich-dark-blue text-sm">
                            <p className="font-semibold mb-[3px]">Company Size</p>
                            <div>{data.companySize.title} Employees</div>
                        </div>
                    </div>
                    <div className="flex gap-[26px]">
                        <div>
                            <div className="bg-brandeis-blue/[0.08] inline-flex items-center justify-center min-w-[43px] min-h-[43px] rounded-[10px]">
                                <img className="w-6 h-6" src={callingIcon} alt="" />
                            </div>
                        </div>
                        <div className="text-versich-dark-blue text-sm">
                            <p className="font-semibold mb-[3px]">Phone Number</p>
                            <div>{data.phone.code} {data.phone.number}</div>
                        </div>
                    </div>
                    {((hideEmpty && data.website) || !hideEmpty) &&
                        <SocialLink href={data.website} icon={sendIcon} label="Website" />
                    }
                </div>
                <div className="flex gap-[26px]">
                    <div>
                        <div className="bg-brandeis-blue/[0.08] inline-flex items-center justify-center min-w-[43px] min-h-[43px] rounded-[10px]">
                            <img className="w-6 h-6" src={locationIcon} alt="" />
                        </div>
                    </div>
                    <div className="text-versich-dark-blue text-sm">
                        <p className="font-semibold mb-[3px]">Address</p>
                        <div>{data.address}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default About