import RichTextField from "../RichTextField/RichTextField"

const RichTextFieldInput = ({ name, label, placeholder, required, onChange, defaultValue, error }) => {
    return (
        <div className="space-y-2">
            {label &&
                <div className="flex flex-wrap gap-2 items-center justify-between">
                    <label htmlFor={name} className="text-versich-dark-blue text-base font-medium">{label}{required && <span className="text-red-500 ml-1">*</span>}</label>
                </div>
            }
            <div className={"w-[99%] mx-auto no-twp "
                + (error ? "_error" : "")
            }>
                <RichTextField onChange={onChange} value={defaultValue} placeholder={placeholder} />
            </div>
            {error &&
                <div className="text-sm text-red-500 text-left">
                    {required}
                </div>
            }
        </div>
    )
}
export default RichTextFieldInput
