import { useState } from "react"
import { useNavigate, useLocation, Link } from "react-router-dom"
import { BiErrorCircle } from "react-icons/bi"

import Meta from "../../../components/Meta"
import BrandBar from "../../../components/Navbar/BrandBar"
import ConfirmButton from "../../../components/Buttons/ConfirmButton"
import talentSearchIcon from "../../../assets/svgs/talent-search.svg"
import freelancerIcon from "../../../assets/svgs/freelancer.svg"
import businessDealIcon from "../../../assets/svgs/business-deal.svg"
import {
    SS_ACCOUNT_TYPE,
    CLIENT, FREELANCER, BUSINESS,
    loginPath, clientSignupPath, freelancerSignupPath, businessSignupPath
} from "../../../assets/constants"

const metadata = {
    title: "Account Selection",
    description: "Account type selection",
    url: `${process.env.REACT_APP_CLIENT_URL}/auth/signup/`
}

const AccountSelection = () => {
    const [selectedOption, setSelectedOption] = useState("")
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()
    const handleAccountSelectionSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        sessionStorage.setItem(SS_ACCOUNT_TYPE.key, selectedOption)
        await new Promise((resolve) => setTimeout(resolve, 200)) // 0.2s delay
        switch (selectedOption) {
            case CLIENT:
                navigate(clientSignupPath)
                break
            case FREELANCER:
                navigate(freelancerSignupPath)
                break
            case BUSINESS:
                navigate(businessSignupPath)
                break
            default:
                setErrorMsg("Please select an account type")
                break
        }
        setLoading(false)
    }

    const location = useLocation()
    return (
        <div className="min-h-svh">
            <Meta data={metadata} />
            <BrandBar />
            <div className="flex flex-col overflow-hidden relative">
                <div className="bg-versich-light-blue absolute w-full h-[50%]" />
                <div className="bg-versich-light-blue absolute top-[50%] w-full h-[50%] [clip-path:polygon(0%_0%,_0%_80px,_100%_0%)]" />
                <div className="max_screen_w relative">
                    <h2 className="text-white text-2xl sm:text-3xl leading-normal text-center font-medium px-12 max-[400px]:px-6 py-10 sm:py-16">Join as a Client or Freelancer or Business</h2>
                    <div className="bg-[#FDFEFF] rounded-2xl mb-20">
                        <div className="bg-brandeis-blue/[0.08] py-10 sm:py-16 flex justify-center rounded-2xl">
                            <form className="px-12 max-[400px]:px-6 mx-0 max-w-[1200px]" onSubmit={handleAccountSelectionSubmit}>
                                {errorMsg &&
                                    <div className="w-full pb-6 sm:pb-10 flex justify-center">
                                        <p className="bg-amber-200 text-amber-950 font-semibold flex smaller:items-center justify-center gap-2 w-full max-w-[700px] px-6 py-1.5 sm401:px-12 sm401:py-2 border border-amber-300 rounded-md">
                                            <BiErrorCircle className="w-6 h-6" />
                                            <span>{errorMsg}</span>
                                        </p>
                                    </div>
                                }
                                <div className="grid smaller:grid-cols-2 md:grid-cols-3 gap-4 sm:gap-x-8">
                                    <label
                                        onClick={() => { setErrorMsg(""); setSelectedOption(CLIENT) }}
                                        className={"bg-white min-h-40 flex flex-col justify-start items-center gap-y-2 p-6 max-sm:px-4 border-[3px] hover:border-[#5DA6F4]/60 rounded-lg cursor-pointer transition-colors group " + (
                                            selectedOption === CLIENT ? "!border-[#5DA6F4]" : "border-transparent shadow-selection"
                                        )}
                                    >
                                        <img className="w-16 h-16 my-3" src={talentSearchIcon} alt="" />
                                        <p className="text-versich-dark-blue font-medium text-sm sm:text-base max-w-52">
                                            <input className="appearance-none" type="radio" value={CLIENT} />
                                            I’m a client, looking for freelancers or consultants
                                        </p>
                                    </label>
                                    <label
                                        onClick={() => { setErrorMsg(""); setSelectedOption(FREELANCER) }}
                                        className={"bg-white min-h-40 flex flex-col justify-start items-center gap-y-2 p-6 max-sm:px-4 border-[3px] hover:border-[#5DA6F4]/60 rounded-lg cursor-pointer transition-colors group " + (
                                            selectedOption === FREELANCER ? "!border-[#5DA6F4]" : "bg-white border-transparent shadow-[0px_0px_12px_6px_rgb(0,0,0,0.05)]"
                                        )}
                                    >
                                        <img className="w-16 h-16 my-3" src={freelancerIcon} alt="" />
                                        <p className="text-versich-dark-blue font-medium text-sm sm:text-base max-w-52">
                                            <input className="appearance-none" type="radio" value={FREELANCER} />
                                            I’m a freelancer or independent consultant, looking for work
                                        </p>
                                    </label>
                                    <label
                                        onClick={() => { setErrorMsg(""); setSelectedOption(BUSINESS) }}
                                        className={"bg-white smaller:max-md:col-span-2 smaller:max-md:mx-auto min-h-40 smaller:max-md:inline-flex flex flex-col justify-start items-center gap-y-2 p-6 max-sm:px-4 border-[3px] hover:border-[#5DA6F4]/60 rounded-lg cursor-pointer transition-colors group " + (
                                            selectedOption === BUSINESS ? "!border-[#5DA6F4]" : "bg-white border-transparent shadow-[0px_0px_12px_6px_rgb(0,0,0,0.05)]"
                                        )}
                                    >
                                        <img className="w-16 h-16 my-3" src={businessDealIcon} alt="" />
                                        <p className="text-versich-dark-blue font-medium text-sm sm:text-base max-w-52">
                                            <input className="appearance-none" type="radio" value={BUSINESS} />
                                            A business looking to sell business’ services and boost visibility for brand
                                        </p>
                                    </label>
                                </div>
                                <div className="w-full max-w-[320px] mx-auto mt-10 space-y-2 relative">
                                    <ConfirmButton type="submit" loading={loading}>{
                                        (selectedOption === CLIENT && "Become a Client")
                                        || (selectedOption === FREELANCER && "Join as a Freelancer")
                                        || (selectedOption === BUSINESS && "List your Business")
                                        || "Create"
                                    }</ConfirmButton>
                                </div>
                                <p className="text-sm text-center mt-4">
                                    Already have an account?{" "}
                                    <Link to={loginPath + location.search} className="text-versich-blue hover:text-versich-blue-hover hover:underline font-semibold">Log in</Link>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AccountSelection
