import { useSelector, useDispatch } from "react-redux"
import { useForm, FormProvider } from "react-hook-form"
import { useState, useEffect } from "react"
import { FaCheck } from "react-icons/fa"

import curvedEditIcon from "../../../../assets/svgs/curved-edit.svg"
import DialogBox, { closeDialog, getDialog, openDialog } from "../../../DialogBox"
import FormLoader from "../../../loader/FormLoader"
import Close from "../../../svgs/Close"
import CSRFTokenField from "../../../Inputs/CSRFTokenField"
import InputText from "../../../Inputs/InputTextField"
import CountrySelectionField from "../../../Inputs/CountrySelectionField"
import PhoneNumberField, { validateNumber } from "../../../Inputs/PhoneNumberField"
import toastify from "../../../../utils/toastify"
import { businessLocationUpdateAsync } from "../../../../slices/business/location"
import { TOASTTYPE_ERROR } from "../../../../assets/constants"

const LocationDetails = ({ data }) => {
    return (
        <div className="space-y-8">
            <div className="grid grid-cols-1 sm:max-md:grid-cols-2 lg900:max-xl1080:grid-cols-2 xl:grid-cols-2 gap-x-3 gap-y-6">
                <div className="text-sm">
                    <p className="font-medium opacity-80 mb-[7px]">Address Line 1</p>
                    <div className="font-semibold">{data.addressLine1}</div>
                </div>
                <div className="text-sm">
                    <p className="font-medium opacity-80 mb-[7px]">Address Line 2</p>
                    <div className="font-semibold">{data.addressLine2 || "-"}</div>
                </div>
            </div>
            <div className="grid grid-cols-1 sm:max-md:grid-cols-2 lg900:max-xl1080:grid-cols-2 xl:grid-cols-2 gap-x-3 gap-y-6">
                <div className="text-sm">
                    <p className="font-medium opacity-80 mb-[7px]">City</p>
                    <div className="font-semibold">{data.city}</div>
                </div>
                <div className="text-sm">
                    <p className="font-medium opacity-80 mb-[7px]">Country</p>
                    <div className="font-semibold">{data.country.name}</div>
                </div>
            </div>
            <div className="text-sm">
                <p className="font-medium opacity-80 mb-[7px]">Phone Number</p>
                <div className="font-semibold">{data.phone.code} {data.phone.number}</div>
            </div>
        </div>
    )
}

const BusinessLocation = () => {
    const { user } = useSelector((state) => state.auth)
    const methods = useForm()

    const [csrfToken, setCsrfToken] = useState("")
    const [hqAddressLine1, setHQAddressLine1] = useState("")
    const [hqAddressLine2, setHQAddressLine2] = useState("")
    const [hqPhoneCode, setHQPhoneCode] = useState("")
    const [hqPhoneNumber, setHQPhoneNumber] = useState("")
    const [hqPhoneNumberValid, setHQPhoneNumberValid] = useState(false)
    const [hqCity, setHQCity] = useState("")
    const [hqCountry, setHQCountry] = useState("")
    const [adAddressLine1, setADAddressLine1] = useState("")
    const [adAddressLine2, setADAddressLine2] = useState("")
    const [adPhoneCode, setADPhoneCode] = useState("")
    const [adPhoneNumber, setADPhoneNumber] = useState("")
    const [adPhoneNumberValid, setADPhoneNumberValid] = useState(false)
    const [adCity, setADCity] = useState("")
    const [adCountry, setADCountry] = useState("")
    const [regionError, setRegionError] = useState(false)
    const [sameAddress, setSameAddress] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    // Pre-fill Form
    const resetForm = () => {

        // Headquarters
        setHQAddressLine1(user.business.location.data.headquarters.addressLine1)
        setHQAddressLine2(user.business.location.data.headquarters.addressLine2)
        if (user.business.location.data.headquarters.phone) {
            const code = user.business.location.data.headquarters.phone.code.replace("+", "")
            const fullPhoneNumber = code + user.business.location.data.headquarters.phone.number
            setHQPhoneCode(code)
            setHQPhoneNumber(user.business.location.data.headquarters.phone.number)
            setHQPhoneNumberValid(validateNumber(fullPhoneNumber))
        }
        setHQCity(user.business.location.data.headquarters.city)
        setHQCountry(user.business.location.data.headquarters.country.iso3)

        // Additional
        setADAddressLine1(user.business.location.data.additional.addressLine1)
        setADAddressLine2(user.business.location.data.additional.addressLine2)
        if (user.business.location.data.additional.phone) {
            const code = user.business.location.data.additional.phone.code.replace("+", "")
            const fullPhoneNumber = code + user.business.location.data.additional.phone.number
            setADPhoneCode(code)
            setADPhoneNumber(user.business.location.data.additional.phone.number)
            setADPhoneNumberValid(validateNumber(fullPhoneNumber))
        }
        setADCity(user.business.location.data.additional.city)
        setADCountry(user.business.location.data.additional.country.iso3)
    }
    useEffect(() => {
        resetForm()
    }, [user])

    // Close dialog
    const customCloseDialog = (e) => {
        resetForm()
        closeDialog(e)
    }

    const dispatch = useDispatch()
    const customHandleSubmit = (e) => {
        e.preventDefault()
        if (!hqCountry || !adCountry) {
            setRegionError(true)
        }
        methods.handleSubmit(onSubmit)(e)
    }
    const onSubmit = async (data, event) => {
        try {
            const isValid = await methods.trigger()
            if (!isValid || !hqPhoneNumberValid || (!sameAddress && !adPhoneNumberValid)) {
                toastify("Form validation failed", TOASTTYPE_ERROR)
                return methods.reset()
            }
            const formData = {
                hqaddressline1: data.hqAddressLine1,
                hqaddressline2: data.hqAddressLine2,
                hqphonecode: `+${hqPhoneCode}`,
                hqphonenumber: hqPhoneNumber,
                hqcity: data.hqCity,
                hqcountryiso3: hqCountry,
                ...(
                    sameAddress
                        ? {
                            adaddressline1: data.hqAddressLine1,
                            adaddressline2: data.hqAddressLine2,
                            adphonecode: `+${hqPhoneCode}`,
                            adphonenumber: hqPhoneNumber,
                            adcity: data.hqCity,
                            adcountryiso3: hqCountry,
                        }
                        : {
                            adaddressline1: data.adAddressLine1,
                            adaddressline2: data.adAddressLine2,
                            adphonecode: `+${adPhoneCode}`,
                            adphonenumber: adPhoneNumber,
                            adcity: data.adCity,
                            adcountryiso3: adCountry,
                        }
                ),
                token: csrfToken,
            }
            setLoading(true)
            await dispatch(businessLocationUpdateAsync(formData))
                .unwrap()
                .then(() => {
                    const dialog = getDialog(event.target)
                    if (dialog) {
                        closeDialog(null, dialog)
                    }
                    methods.reset()
                    return setLoading(false)
                })
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    return setErrorMsg(data.message)
                })
                .finally(() => setLoading(false))
        } catch (error) {
            console.error("Form submission error:", error)
        }
    }
    return (
        <div className="bg-white text-start p-4 sm:p-5 shadow-card5 rounded-lg">
            <div className="font-medium flex max-sm401:flex-col sm401:justify-between gap-x-3 gap-y-1.5 py-3 border-b border-[#EFEEEE99]">
                <span className="text-versich-dark-blue text-xl">Business Locations</span>
                <button className="inline-flex items-center gap-1 hover:opacity-80" onClick={openDialog} data-parent-id={locationsEditDialog}>
                    <img className="w-[18px] h-[18px]" src={curvedEditIcon} alt="" data-parent-id={locationsEditDialog} />
                    <span className="text-versich-light-blue text-xs" data-parent-id={locationsEditDialog}>Edit</span>
                </button>
            </div>
            <div className="text-versich-dark-blue my-5">
                <div className="text-base font-medium mb-5">Location - Headquarters</div>
                <LocationDetails data={user.business.location.data.headquarters} />
                <div className="text-base font-medium my-5 pt-5 border-t border-[#EFEEEE99]/50">Additional Location</div>
                <LocationDetails data={user.business.location.data.additional} />
            </div>
            <DialogBox
                id={locationsEditDialog}
                className="backdrop:bg-[#1717253D] backdrop:backdrop-blur-sm max-md:max-w-lg lg:w-3/4 2xl:!w-2/3 !max-w-4xl"
                toastStacked={false}
                hideClose={true}
                customCloseEvent={customCloseDialog}
            >
                <div className="space-y-[34px] p-2.5 md:p-4">
                    <div className="flex gap-1.5">
                        <div className="text-versich-dark-blue flex-1">
                            <div className="text-xl font-semibold">Business Locations</div>
                            <div className="text-sm mt-0.5">Manage your business locations</div>
                        </div>
                        <div id="dialog_closebtn" className="text-right">
                            <button type="button" className="m-1 p-1" onClick={customCloseDialog} data-parent-id={locationsEditDialog}>
                                <Close className="w-4 h-4" />
                            </button>
                        </div>
                    </div>
                    <FormProvider {...methods}>
                        <form className="space-y-[34px] md:space-y-7" method="dialog" onSubmit={customHandleSubmit} data-parent-id={locationsEditDialog}>
                            <div className="grid md:grid-cols-2 gap-6">
                                <div>
                                    <p className="text-versich-dark-blue text-base text-left font-medium">Location - Headquarters</p>
                                    <div className="space-y-3 py-5">
                                        <InputText name="hqAddressLine1" inputType="text" placeholder="Enter Address" label="Address Line 1" onChange={(e) => setHQAddressLine1(e.target.value)} defaultValue={hqAddressLine1} rules={{ required: "Address is required" }} />
                                        <InputText name="hqAddressLine2" inputType="text" placeholder="Enter Address" label="Address Line 2" onChange={(e) => setHQAddressLine2(e.target.value)} defaultValue={hqAddressLine2} />
                                        <PhoneNumberField
                                            name="hqphonenumber"
                                            rules={{ required: "Phone number is required" }}
                                            setPhoneCode={setHQPhoneCode}
                                            setPhoneNumber={setHQPhoneNumber}
                                            setPhoneNumberValid={setHQPhoneNumberValid}
                                            value={hqPhoneCode + hqPhoneNumber}
                                        />
                                        <div className="grid grid-cols-1 gap-x-3 gap-5 lg:grid-cols-2">
                                            <InputText name="hqCity" inputType="text" placeholder="Your City" label="City" onChange={(e) => setHQCity(e.target.value)} defaultValue={hqCity} rules={{ required: "City is required" }} />
                                            <CountrySelectionField name="hqCountry" label="Country" selectedCountry={hqCountry} onChange={(e) => setHQCountry(e.target.value)} regionError={regionError} />
                                        </div>
                                    </div>
                                    <label className="w-full flex items-center gap-2 max-md:mt-5 max-md:pt-5 max-md:border-t border-[#C7D0FB] selection:bg-transparent cursor-pointer group">
                                        <input className="appearance-none" name="sameaddress" type="checkbox" onChange={(e) => setSameAddress(checked => !checked)} />
                                        <div className="inline-flex items-center justify-center w-[18px] h-[18px] border-2 border-versich-blue rounded-md before:absolute before:w-1.5 before:h-1.5 before:group-hover:bg-versich-blue before:rounded-sm">
                                            {sameAddress && <FaCheck className="bg-white fill-versich-blue-hover w-2.5 h-2.5 relative" />}
                                        </div>
                                        <span className="text-versich-dark-blue text-sm font-medium">Same as Headquarter Address</span>
                                    </label>
                                </div>
                                <div>
                                    <p className="text-versich-dark-blue text-base text-left font-medium">Additional Location</p>
                                    <div className="space-y-3 py-5">
                                        <InputText name="adAddressLine1" inputType="text" placeholder="Enter Address" label="Address Line 1" onChange={(e) => setADAddressLine1(e.target.value)} defaultValue={sameAddress ? hqAddressLine1 : adAddressLine1} rules={{ required: "Address is required" }} readOnly={sameAddress} />
                                        <InputText name="adAddressLine2" inputType="text" placeholder="Enter Address" label="Address Line 2" onChange={(e) => setADAddressLine2(e.target.value)} defaultValue={sameAddress ? hqAddressLine2 : adAddressLine2} readOnly={sameAddress} />
                                        <PhoneNumberField
                                            name="adphonenumber"
                                            rules={{ required: "Phone number is required" }}
                                            setPhoneCode={setADPhoneCode}
                                            setPhoneNumber={setADPhoneNumber}
                                            setPhoneNumberValid={setADPhoneNumberValid}
                                            value={
                                                sameAddress ? hqPhoneCode + hqPhoneNumber : adPhoneCode + adPhoneNumber
                                            }
                                            readOnly={sameAddress}
                                        />
                                        <div className="grid grid-cols-1 gap-x-3 gap-5 lg:grid-cols-2">
                                            <InputText name="adCity" inputType="text" placeholder="Your City" label="City" onChange={(e) => setADCity(e.target.value)} defaultValue={sameAddress ? hqCity : adCity} rules={{ required: "City is required" }} readOnly={sameAddress} />
                                            <CountrySelectionField name="adCountry" label="Country" selectedCountry={sameAddress ? hqCountry : adCountry} onChange={(e) => setADCountry(e.target.value)} regionError={regionError} readOnly={sameAddress} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                            {errorMsg && <div className="text-sm text-red-500 text-left mt-8 mb-4">{errorMsg}</div>}
                            <div className="text-sm font-semibold w-full flex max-sm401:flex-col justify-end gap-3 md:gap-5">
                                <button className="bg-white hover:opacity-80 text-versich-light-blue border border-versich-light-blue py-2.5 md:py-3 px-8 md:px-10 rounded-lg" type="button" onClick={customCloseDialog} data-parent-id={locationsEditDialog}>Cancel</button>
                                <button className="bg-versich-blue hover:bg-versich-blue-hover disabled:bg-versich-blue-hover text-white disabled:opacity-70 py-2.5 md:py-3 px-8 md:px-10 rounded-lg disabled:cursor-not-allowed" type="submit">Update</button>
                            </div>
                        </form>
                    </FormProvider>
                </div>
                {loading && <FormLoader />}
            </DialogBox>
        </div>
    )
}
const locationsEditDialog = "businesslocations-edit-dialog"
export default BusinessLocation