import { useEffect } from "react"

import documentEditIcon from "../../../../../assets/icons/request/document-edit.png"
import NewRequestLayout from "../NewRequestLayout"
import RichTextField from "../../../../../components/RichTextField/RichTextField"

const Description = ({ totalForms, formPosition, updateFormPosition, description, setDescription, error, goBack }) => {
    useEffect(() => {
        updateFormPosition()
    }, [])
    return (
        <NewRequestLayout
            icon={documentEditIcon}
            description="Explain what you need so we get you experts to meet your needs"
            totalForms={totalForms} currentForm={formPosition}
            handleBackClick={goBack}
            widerForm={true}
        >
            <div className="relative">
                <p className="text-versich-dark-blue text-lg font-semibold pb-5">Describe what you need, The more detailed, the better</p>
                {error && <p className="text-red-500 text-start text-sm mb-3">Required description, can't leave it empty.</p>}
                <div className={"mb-5 no-twp "
                    + (error ? "_error" : "")
                }>
                    <RichTextField onChange={(value) => setDescription(value)} value={description} />
                </div>
            </div>
        </NewRequestLayout>
    )
}
export default Description
